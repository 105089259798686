// import Navbar from "../../Components/Navbar";
import bloger from "../../assets/blog/tio.png";
import profile from "../../assets/blog/perfil.jpeg";
import Footer from "../../Components/Footer";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Navbar from "../../Components/Navbar";

export default function Post() {
  const history = useHistory();
  const { title } = useParams();

  const [languageChanged, setLanguageChanged] = useState(false);

  const { t, i18n } = useTranslation();
  const [post, setPost] = useState(null);
  const [randomPost, setRandomPost] = useState([]);

  useEffect(() => {
    const postData = async () => {
      try {
        const response = await axios.get(`api/show/article/${title}`);

        // Verificar si la respuesta tiene el código de estado 200
        if (response.status === 200) {
          setPost(response.data.article);
        } else {
          console.error("Error fetching post details:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    postData();
  }, [title, languageChanged]);

  useEffect(() => {
    const randomArticles = async () => {
      try {
        const response = await axios.get(`api/random/articles`);

        // Verificar si la respuesta tiene el código de estado 200
        if (response.status === 200) {
          setRandomPost(response.data);
          console.log(response.data);
        } else {
          console.error("Error fetching post details:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    randomArticles();
  }, [languageChanged]);

  useEffect(() => {
    // Actualiza el estado para forzar la actualización
    setLanguageChanged((prev) => !prev);
  }, [i18n.language]);

  if (!post) {
    return <p>Cargando...</p>;
  }

  const handleShow = (title, postId) => {
    const encodedTitle = encodeURIComponent(title);
    // Redirigir al usuario a la página de edición con el título en la URL
    history.push(`/en/Blog/Post/${encodedTitle}`, { postId });
  };

  return (
    <>
      <div className="flex flex-col w-full min-h-screen">
        <Navbar />
        <div className="flex flex-col flex-1 pl-4 pr-4 mt-10 lg:pl-8 lg:flex-row ">
          <div className="w-full lg:w-3/4 ">
            <div className="w-full">
              <img
                src={`https://api.kaylamrealty.com/images/${post.file}`}
                alt=""
              />
            </div>
            <div className="pt-10 pb-8 pl-5 pr-5 lg:pl-8 lg:pr-8 bg-gris-blog">
              <div className="flex flex-col gap-y-4">
                <div>
                  <h1 className="text-2xl font-semibold lg:text-4xl">
                    {t(post[`titulo_${i18n.language}`])}
                  </h1>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center gap-x-4 ">
                    <div className="flex items-center justify-center rounded-full h-14 w-14 ">
                      <img
                        src={profile}
                        className="w-full h-full rounded-full"
                        alt=""
                      />
                    </div>
                    <h3 className="text-base lg:text-lg">Lamia Badache</h3>
                  </div>
                  <div>
                    <h4 className="text-xs lg:text-sm">
                      {" "}
                      {moment(post.created_at).format("DD/MM/YYYY")}
                    </h4>
                  </div>
                </div>
                <div
                  className="text-justify text-gris"
                  dangerouslySetInnerHTML={{
                    __html: t(post[`contenido_${i18n.language}`]),
                  }}
                ></div>
              </div>
            </div>
            <div className="flex flex-col h-auto p-8 mt-10 gap-y-6 bg-gris-blog">
              <div>
                <h2 className="text-xl font-semibold lg:text-3xl">
                  About the author
                </h2>
              </div>

              <div className="flex items-center gap-x-4">
                <div className="flex items-center justify-center rounded-full h-14 w-14 ">
                  <img
                    src={profile}
                    className="w-full h-full rounded-full"
                    alt=""
                  />
                </div>{" "}
                <h4 className="text-sm font-semibold lg:text-base">
                  Lamia Badache
                </h4>
              </div>
              <div>
                <h3 className="text-base lg:text-lg">{t("Blog-Lamia")}</h3>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full pl-2 pr-2 mt-10 lg:pr-2 lg:mt-0 gap-y-4 lg:w-1/4 ">
            <div className="h-10">
              <h2 className="text-2xl font-semibold">Related Posts</h2>
            </div>

            <div className="flex flex-col w-full gap-y-6">
              {randomPost.map((article, index) => (
                <div
                  className="flex flex-col w-full p-2 border rounded gap-y-2"
                  key={index}
                >
                  <div className="w-full h-52">
                    <img
                      src={`https://api.kaylamrealty.com/images/${article.file}`}
                      className="w-full h-full rounded"
                      alt="lola"
                    />
                  </div>
                  <div>
                    <h4 className="text-sm">
                      {moment(article.created_at).format("DD/MM/YYYY")}
                    </h4>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        handleShow(
                          article[`titulo_${i18n.language}`],
                          article.id
                        )
                      }
                    >
                      <h3 className="text-lg">
                        {t(article[`titulo_${i18n.language}`])}
                      </h3>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
