import logo from "../assets/logo/logonewnobg.png";
import { NavLink, Link } from "react-router-dom";
import "../App.css";
import { useTranslation } from "react-i18next";
import { slide as Menu } from "react-burger-menu";
import LanguageComponent from "./Language";
export default function Navbar() {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-end w-full h-32 lg:h-40 bg-marino">
        <nav className="relative items-center hidden w-full pl-12 lg:order-first lg:flex h-36 bg-marino">
          <ul className="z-30 flex w-full justify-evenly">
            <li>
              <NavLink
                to="/:lang/"
                activeClassName="active"
                className="text-white"
                exact
              >
                {t("Inicio")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/:lang/Buy"
                activeClassName="active"
                className="text-white"
                exact
              >
                {t("Comprar")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/:lang/sell"
                activeClassName="active"
                className="text-white"
                exact
              >
                {t("Vender")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/:lang/Rent"
                activeClassName="active"
                className="text-white"
                exact
              >
                {t("Alquilar")}
              </NavLink>
            </li>
          </ul>
          <Link to="/:lang/" className="w-1/3 h-full">
            <img
              src={logo}
              className="z-30 object-contain w-full h-full "
              alt="logo"
            />
          </Link>

          <ul className="z-30 flex justify-around w-full">
            <li>
              <NavLink
                to="/:lang/About"
                activeClassName="active"
                className="text-white"
                exact
              >
                {t("Sobre_Nosotros")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/:lang/Blog"
                activeClassName="active"
                className="text-white"
                exact
              >
                {t("Blog")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/:lang/Contact"
                activeClassName="active"
                className="text-white"
                exact
              >
                {t("Contacto")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/:lang/Favourites"
                activeClassName="active"
                className="text-white"
                exact
              >
                {t("Favoritos")}
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="relative z-50 flex justify-center w-full h-32 font-sans bg-marino lg:hidden">
          <Menu>
            <NavLink
              to="/:lang"
              activeClassName="active"
              className="text-white"
              exact
            >
              {t("Inicio")}
            </NavLink>
            <NavLink
              to="/:lang/Buy"
              activeClassName="active"
              className="text-white"
              exact
            >
              {t("Comprar")}
            </NavLink>
            <NavLink
              to="/:lang/Sell"
              activeClassName="active"
              className="text-white"
              exact
            >
              {t("Vender")}
            </NavLink>
            <NavLink
              to="/:lang/Rent"
              activeClassName="active"
              className="text-white"
              exact
            >
              {t("Alquilar")}
            </NavLink>
            <NavLink
              to="/:lang/About"
              activeClassName="active"
              className="text-white"
              exact
            >
              {t("Sobre_Nosotros")}
            </NavLink>
            <NavLink
              to="/:lang/Blog"
              activeClassName="active"
              className="text-white"
              exact
            >
              Blog
            </NavLink>
            <NavLink
              to="/:lang/Contact"
              activeClassName="active"
              className="text-white"
              exact
            >
              {t("Contacto")}
            </NavLink>
            <NavLink
              to="/:lang/Favourites"
              activeClassName="active"
              className="text-white"
              exact
            >
              {t("Favoritos")}
            </NavLink>
          </Menu>

          <div className="z-0 flex items-center justify-end w-full h-32 pr-12">
            <img src={logo} className="w-40" alt="nobg" />
          </div>
        </div>
        <LanguageComponent />
      </div>
    </>
  );
}
