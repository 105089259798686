import React from "react";
import swal from "sweetalert";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import logo from "../../assets/logo/footer-l.png";
const Login = () => {
  const history = useHistory();

  // const [imagedata, setImagedata] = useState({
  //     file: "",
  // });

  // const handleImage = (file) => {
  //     setImagedata(file[0]);
  // };

  const [loginInput, setLogin] = useState({
    email: "",
    password: "",
    error_list: [],
  });

  const handleInputs = (e) => {
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`api/login`, data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_email", res.data.email);
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("type", res.data.type);
          history.push("/en/Admin");
        } else if (res.data.status === 401) {
          swal("Warning", res.data.message, "warning");
        } else if (res.data.status === 403) {
          swal("Warning", res.data.message, "warning");
          localStorage.removeItem("auth_name", res.data.username);
          localStorage.removeItem("auth_token", res.data.token);
          history.push("/en");
        } else {
          console.log(res.data);
          setLogin({ ...loginInput, error_list: res.data.validation_errors });
        }
      });
    });
  };

  return (
    <>
      <section className="section">
        <div className="container" id="container">
          <div className="user signinBx">
            <div className="imgBx">
              <img src={logo} alt="logo" />
            </div>
            <div className="formBx">
              <form onSubmit={loginSubmit}>
                <h2>Inicia Sesion</h2>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={handleInputs}
                  value={loginInput.email}
                />
                <span className="text-danger">
                  {loginInput.error_list.email}
                </span>
                <input
                  type="password"
                  placeholder="Contraseña"
                  name="password"
                  onChange={handleInputs}
                  value={loginInput.password}
                />
                <span className="text-danger">
                  {loginInput.error_list.password}
                </span>
                <input type="submit" value="Conéctate" />
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
