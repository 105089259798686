import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

export default function CreatePost() {
  const [formData, setFormData] = useState({
    titulo_es: "",
    contenido_es: "",
    titulo_fr: "",
    contenido_fr: "",
    titulo_en: "",
    contenido_en: "",
    titulo_de: "",
    contenido_de: "",
    titulo_nl: "",
    contenido_nl: "",
    titulo_ru: "",
    contenido_ru: "",
    file: null,
  });

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      file,
    }));

    console.log("Selected file:", file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    data.append("titulo_es", formData.titulo_es);
    data.append("contenido_es", formData.contenido_es);
    data.append("titulo_en", formData.titulo_en);
    data.append("contenido_en", formData.contenido_en);
    data.append("titulo_fr", formData.titulo_fr);
    data.append("contenido_fr", formData.contenido_fr);
    data.append("titulo_de", formData.titulo_de);
    data.append("contenido_de", formData.contenido_de);
    data.append("titulo_nl", formData.titulo_nl);
    data.append("contenido_nl", formData.contenido_nl);
    data.append("titulo_ru", formData.titulo_ru);
    data.append("contenido_ru", formData.contenido_ru);
    if (formData.file) {
      data.append("file", formData.file);
    }

    console.log("FormData content:", [...data]);
    try {
      const response = await axios.post("api/create/post", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      // Check if the response has errors
      if (response.data.errors) {
        console.log("Validation errors:", response.data.errors);
        // Handle errors as needed, you might want to display them to the user
        return;
      }

      console.log(response.data);

      // Verificar el código de estado de la respuesta
      if (response.status === 200) {
        console.log("¡Post creado con éxito!");
        // Redirigir a la página deseada
        history.push("/en/Admin");
        swal("Buen trabajo!", "Articulo creado !", "success");
      }
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
      console.log("Server response:", error.response); // Log the response for more details
    }
  };

  return (
    <>
      <form
        className="min-h-screen"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <div class="grid gap-6 mb-6 md:grid-cols-1 w-10/12 mx-auto my-0 pt-10">
          <div>
            <label
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Titulo - ES
            </label>
            <input
              onChange={handleChange}
              value={formData.titulo_es}
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              name="titulo_es"
            />
          </div>
          <div>
            <label
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Contenido - ES
            </label>
            <textarea
              onChange={handleChange}
              value={formData.contenido_es}
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              name="contenido_es"
            />
          </div>
          <div>
            <label
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Titulo - FR
            </label>
            <input
              onChange={handleChange}
              value={formData.titulo_fr}
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              name="titulo_fr"
            />
          </div>
          <div>
            <label
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Contenido - FR
            </label>
            <textarea
              onChange={handleChange}
              value={formData.contenido_fr}
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              name="contenido_fr"
            />
          </div>
          <div>
            <label
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Titulo - EN
            </label>
            <input
              onChange={handleChange}
              value={formData.titulo_en}
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              name="titulo_en"
            />
          </div>
          <div>
            <label
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Contenido - EN
            </label>
            <textarea
              onChange={handleChange}
              value={formData.contenido_en}
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              name="contenido_en"
            />
          </div>
          <div>
            <label
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Titulo - DE
            </label>
            <input
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              onChange={handleChange}
              value={formData.titulo_de}
              name="titulo_de"
            />
          </div>
          <div>
            <label
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Contenido - DE
            </label>
            <textarea
              onChange={handleChange}
              value={formData.contenido_de}
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              name="contenido_de"
            />
          </div>
          <div>
            <label
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Titulo - NL
            </label>
            <input
              onChange={handleChange}
              value={formData.titulo_nl}
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              name="titulo_nl"
            />
          </div>
          <div>
            <label
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Contenido - NL
            </label>
            <textarea
              onChange={handleChange}
              value={formData.contenido_nl}
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              name="contenido_nl"
              required
            />
          </div>
          <div>
            <label
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Titulo - RU
            </label>
            <input
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              onChange={handleChange}
              value={formData.titulo_ru}
              name="titulo_ru"
            />
          </div>
          <div>
            <label
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Contenido - RU
            </label>
            <textarea
              type="text"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              onChange={handleChange}
              value={formData.contenido_ru}
              name="contenido_ru"
            />
          </div>
        </div>
        <div className="w-10/12 mx-auto my-0">
          <label
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            for="file_input"
          >
            Upload file
          </label>
          <input
            class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            type="file"
            name="file"
            onChange={handleFileChange}
          />
        </div>

        <div className="flex items-center justify-center w-full mt-10">
          <button className="w-32 h-10 text-white bg-green-500 rounded hover:bg-verde">
            Crear
          </button>
        </div>
      </form>
    </>
  );
}
