import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import xml2js from "xml2js";
import Cookies from "js-cookie";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [houses, setHouses] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lang = Cookies.get("i18next") || "en"; // default to English

        const response = await axios.get("api/fetchdata");
        const xmlData = response.data.data;

        // Check if XML Data is Defined
        if (!xmlData || xmlData.trim() === "") {
          console.error("XML Data is undefined or empty");
          return;
        }

        // Parse XML to JSON
        try {
          const result = await new Promise((resolve, reject) => {
            xml2js.parseString(
              xmlData,
              { explicitArray: false },
              (err, result) => {
                if (err) reject(err);
                else resolve(result);
              }
            );
          });

          const jsonData = result.propiedades.propiedad;

          // If there's only one property, convert it to an array
          const fetchedHouses = Array.isArray(jsonData) ? jsonData : [jsonData];

          // Sort houses by date or another property as needed
          fetchedHouses.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

          setHouses(fetchedHouses);
          console.log(fetchedHouses);
        } catch (error) {
          console.error("Error parsing XML:", error);
        }

        // Set language cookie
        Cookies.set("i18next", lang);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);

  return <ApiContext.Provider value={houses}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  return useContext(ApiContext);
};
