import Footer from "../../Components/Footer";
import LocationComponent from "../../Components/Location";
import Navbar from "../../Components/Navbar";
import Comillas from "../../assets/images/comillas.png";
import Whatsapp from "../../Components/Whatsapp";
import SliderComponent from "./SliderComponent";
// import Sidebar from "../../Components/Sidebar";
import stage1 from "../../assets/icons/sell/stage.png";
import stage2 from "../../assets/icons/sell/stage2.png";
import stage3 from "../../assets/icons/sell/stage3.png";
import stage4 from "../../assets/icons/sell/stage4.png";
import stages from "../../assets/icons/sell/line-sell.png";
import { useEffect, useState } from "react";
// import LanguageComponent from "../../Components/Language";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import swal from "sweetalert";

export default function Sell() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [registerInput, setRegister] = useState({
    Nombre: "",
    Telefono: "",
    Correo: "",
    Texto: "",
    Direccion: "",
    error_list: [],
  });

  const handleInput = (e) => {
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    data.append("Nombre", registerInput.Nombre);
    data.append("Correo", registerInput.Correo);
    data.append("Telefono", registerInput.Telefono);
    data.append("Texto", registerInput.Texto);
    data.append("Direccion", registerInput.Direccion);

    await axios.post("api/createSeller", data).then((res) => {
      if (res.data.status === 200) {
        swal({
          title: "Mensaje enviado",
          text: "Le contactaremos lo antes posible",
          icon: "success",
          button: "Continuar",
        });
        setRegister({
          Nombre: "",
          Correo: "",
          Telefono: "",
          Texto: "",
          Direccion: "",
        });
      } else {
        setRegister({
          ...registerInput,
          error_list: res.data.validation_errors,
        });
      }
    });
  };

  return (
    <>
      {/* <div className="absolute z-30 flex items-center justify-end w-11/12 h-24 ml-6 bg-marino lg:ml-24 lg:h-1/5 ">
        <LanguageComponent />
      </div> */}
      <div className="w-screen min-h-screen lg:h-screen bg-marino lg:max-h-screen lg:mb-24">
        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>
        {/* <div className="w-full h-24 lg:hidden">
          <Sidebar />
        </div> */}
        <main className="relative flex flex-col w-full h-auto lg:h-full ">
          <Navbar />
          <section className="flex flex-col w-full h-auto lg:h-full lg:flex-row ">
            <div className="w-full h-auto bg-marino lg:h-full">
              <form
                onSubmit={registerSubmit}
                className="flex flex-col w-10/12 h-auto pb-8 mx-auto my-0 lg:gap-y-4 gap-y-2"
              >
                <div className="w-full text-lg font-semibold font-minion lg:text-4xl text-blanco lg:h-12">
                  <h1>{t("Tasa_Gratuita")}</h1>
                </div>
                <div className="w-full font-sans text-xs font-light text-justify lg:text-base text-gris-blanco">
                  <h2>{t("Tasa_Gratuita_Texto")}</h2>
                </div>
                <div className="flex flex-col w-full font-sans gap-y-4">
                  <input
                    type="text"
                    name="Nombre"
                    placeholder={t("Nombre_Completo")}
                    onChange={handleInput}
                    value={registerInput.Nombre}
                    className="z-10 w-full h-6 pl-4 text-xs border border-solid rounded outline-none lg:text-base text-gris-blanco bg-marino border-gris-blanco lg:h-12"
                  />
                  <input
                    type="email"
                    name="Correo"
                    placeholder={t("Correo")}
                    onChange={handleInput}
                    value={registerInput.Correo}
                    className="z-10 w-full h-6 pl-4 text-xs border border-solid rounded outline-none lg:text-base text-gris-blanco bg-marino border-gris-blanco lg:h-12"
                  />
                  <div className="z-10 flex items-center w-full h-6 pl-4 text-xs border border-solid rounded outline-none lg:text-base text-blanco bg-marino border-gris-blanco lg:h-12">
                    <PhoneInput
                      placeholder={t("Telefono")}
                      defaultCountry="ES"
                      international
                      className="text-gris"
                      value={registerInput.Telefono}
                      onChange={(value) =>
                        handleInput({ target: { name: "Telefono", value } })
                      }
                      countryCallingCodeEditable={false}
                    />
                  </div>
                  <input
                    type="text"
                    name="Direccion"
                    placeholder={t("Direccion")}
                    onChange={handleInput}
                    value={registerInput.Direccion}
                    className="z-10 w-full h-6 pl-4 text-xs border border-solid rounded outline-none lg:text-base text-gris-blanco bg-marino border-gris-blanco lg:h-12"
                  />

                  <input
                    type="text"
                    name="Texto"
                    placeholder={t("Descripcion")}
                    onChange={handleInput}
                    value={registerInput.Texto}
                    className="z-10 w-full h-20 pl-4 text-xs border border-solid rounded outline-none lg:text-base text-gris-blanco bg-marino border-gris-blanco lg:h-24"
                  />
                  <button
                    type="submit"
                    className="flex items-center justify-center w-full h-8 mx-auto my-0 text-xs text-center rounded outline-none lg:text-base text-blanco bg-gris-blanco lg:h-12 "
                  >
                    {t("Presupuesto")}
                  </button>
                </div>
              </form>
            </div>
            <div className="relative w-full h-screen lg:h-auto">
              <div className="absolute z-0 flex items-center justify-center w-full bg-center bg-no-repeat bg-cover h-5/6 lg:h-full bg-Sell">
                <div className="w-11/12 opacity-75 h-5/6 lg:h-5/6 bg-marino-oscuro "></div>
                <div className="absolute z-10 flex flex-col w-11/12 p-6 mx-auto my-0 font-sans gap-y-6 lg:gap-y-8 h-5/6 lg:h-5/6 lg:p-10 ">
                  <div className="z-10 flex items-center w-full gap-x-6">
                    <div className="z-10 flex flex-col">
                      <div>
                        <img src={Comillas} className="w-4" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="w-full text-sm text-justify lg:text-lg lg:font-light text-blanco lg:h-12">
                    <h2 className="">
                      {t("Vender_Texto")}
                      <br />
                      {t("Vender_Texto_1")}
                    </h2>
                    <div className="flex justify-end w-full pt-8">
                      <img src={Comillas} className="w-4" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <div className="relative w-full h-64 mb-60 lg:hidden">
        <SliderComponent />
      </div>

      <div className="hidden w-full h-auto mb-40 lg:flex">
        <div className="relative w-10/12 mx-auto my-0">
          <div className="mb-12 text-4xl font-bold text-center font-minion">
            <h2>{t("Etapas")}</h2>
          </div>
          <div className="absolute z-0 w-full mt-8 ml-10">
            <img src={stages} alt="" />
          </div>
          <div className="z-10 flex w-full text-justify gap-x-6">
            <div className="z-10 flex flex-col gap-y-6">
              <div className="z-10 flex items-center justify-center w-full">
                <img className="" src={stage1} alt="" />
              </div>
              <div className="">
                <h2 className="text-2xl font-bold text-black font-minion">
                  {t("Busqueda")}
                </h2>
              </div>
              <div>
                <h3>{t("Busqueda_Texto")}</h3>
              </div>
            </div>

            <div className="flex flex-col gap-y-6">
              <div className="z-10 flex items-center justify-center w-full">
                <img src={stage2} alt="" />
              </div>
              <div className="">
                <h2 className="text-2xl font-bold text-black font-minion">
                  {t("Seleccion")}
                </h2>
              </div>
              <div>
                <h3>{t("Seleccion_Texto")}</h3>
              </div>
            </div>

            <div className="flex flex-col gap-y-6">
              <div className="z-10 flex items-center justify-center w-full">
                <img src={stage3} alt="" />
              </div>
              <div className="">
                <h2 className="text-2xl font-bold text-black font-minion">
                  {t("Negociacion")}
                </h2>
              </div>
              <div>
                <h3>{t("Negociacion_Texto")}</h3>
              </div>
            </div>

            <div className="flex flex-col gap-y-6">
              <div className="z-10 flex items-center justify-center w-full">
                <img src={stage4} alt="" />
              </div>
              <div className="">
                <h2 className="text-2xl font-bold text-black font-minion">
                  {t("Cerrar")}
                </h2>
              </div>
              <div>
                <h3>{t("Cerrar_Texto")}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LocationComponent />

      <Footer />
    </>
  );
}
