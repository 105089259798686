import { useTranslation } from "react-i18next";
import Mapa from "../assets/images/mapes.png";


export default function LocationComponent(){
    const {t} = useTranslation();
    return(
        <>
           <div className="flex justify-center w-full mt-12 min-h-40">
                <section className="flex flex-col items-center w-full h-5/6">
                    <div className="flex flex-col w-full h-full mb-8 gap-y-10 justify-evenly">
                        <div className="w-full">
                            <h2 className="text-2xl font-bold text-center font-minion lg:text-4xl"> {t("Zona")}</h2>
                        </div>
                        <div className="flex justify-center w-full">
                            <img alt="map" src={Mapa} />
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}