import Profile from "../../assets/blog/perfil.jpeg";
import Footer from "../../Components/Footer";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../../Components/Navbar";
const MAX_CARACTERES = 200; // Ajusta este valor según tus necesidades

export default function Blog() {
  const history = useHistory();
  const [randomPost, setRandomPost] = useState([]);

  const [articles, setArticles] = useState([]);

  const [languageChanged, setLanguageChanged] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const randomArticles = async () => {
      try {
        const response = await axios.get(`api/random/articles`);

        // Verificar si la respuesta tiene el código de estado 200
        if (response.status === 200) {
          setRandomPost(response.data);
          console.log(response.data);
        } else {
          console.error("Error fetching post details:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    randomArticles();
  }, [languageChanged]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Llamada a la API usando Axios
        const response = await axios.get("api/articles/page");

        // Verifica si el código de estado es 200 (OK)
        if (response.status === 200) {
          // Actualiza el estado con los datos recibidos
          setArticles(response.data);
        } else {
          // Manejo de otros códigos de estado si es necesario
          console.error("Error al obtener los datos de la API");
        }
      } catch (error) {
        // Manejo de errores en la llamada a la API
        console.error("Error al realizar la llamada a la API", error);
      }
    };

    // Llamada a la función para obtener los datos al montar el componente
    fetchData();
  }, [languageChanged]);

  useEffect(() => {
    // Actualiza el estado para forzar la actualización
    setLanguageChanged((prev) => !prev);
  }, [i18n.language]);

  const handleShow = (title, postId) => {
    const encodedTitle = encodeURIComponent(title);
    // Redirigir al usuario a la página de edición con el título en la URL
    history.push(`/en/Blog/Post/${encodedTitle}`, { postId });
  };

  return (
    <>
      <div className="flex flex-col w-full min-h-screen">
        <Navbar />
        <div className="hidden w-full h-full bg-white lg:flex lg:pb-20">
          <div className="flex flex-col items-center justify-center w-full h-auto p-4 mt-10 gap-y-4">
            <div>
              <h1 className="text-4xl font-semibold font-minion">
                {t("Blog")}
              </h1>
            </div>

            <div>
              <h2 className="text-base text-center lg:text-lg text-gris">
                Elevate Your Lifestyle with Our Range of Premier Properties and
                Expert Guidance
              </h2>
            </div>

            {articles.length > 0 && (
              <div className="flex flex-col w-full mt-10 lg:pl-12 lg:flex-row">
                <div className="flex w-full h-96 lg:w-2/5">
                  <img
                    className="w-full h-full"
                    src={`https://api.kaylamrealty.com/images/${articles[0].file}`}
                    alt={articles[0].titulo_es}
                  />
                </div>

                <div className="flex flex-col justify-center w-full mx-auto my-0 lg:w-2/5 gap-y-4">
                  <div>
                    <button
                      onClick={() =>
                        handleShow(
                          articles[0][`titulo_${i18n.language}`],
                          articles[0].id
                        )
                      }
                    >
                      <h2 className="text-2xl text-left font-minion">
                        {t(articles[0][`titulo_${i18n.language}`])}
                      </h2>
                    </button>
                  </div>
                  <div className="flex items-center w-full h-auto gap-x-4">
                    <div className="flex items-center justify-center w-8 h-8 rounded-full lg:h-14 lg:w-14">
                      <img
                        src={Profile}
                        className="w-full h-full rounded-full"
                        alt=""
                      />
                    </div>

                    <h4>Lamia Badache</h4>
                  </div>
                  <div
                    className="text-justify text-gris"
                    dangerouslySetInnerHTML={{
                      __html:
                        t(articles[0][`contenido_${i18n.language}`]).substring(
                          0,
                          MAX_CARACTERES
                        ) + "...",
                    }}
                  ></div>
                  <div>
                    <button
                      className="flex items-center justify-center h-10 text-sm text-white rounded w-36 bg-gris-blanco"
                      onClick={() =>
                        handleShow(
                          articles[0][`titulo_${i18n.language}`],
                          articles[0].id
                        )
                      }
                    >
                      Read the Blog Post
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <section className="flex flex-col w-full h-auto mt-20 bg-white lg:mt-10 lg:flex-row">
          <div className="w-full h-full pl-6 pr-6 lg:pl-16 lg:w-4/6">
            <div className="flex items-center justify-center h-10 lg:justify-start">
              <h2 className="text-3xl font-semibold font-minion">
                Our Latest Blog Posts
              </h2>
            </div>

            <div className="mt-10 ">
              <div className="flex-col hidden pr-4 lg:grid lg:grid-cols-2 gap-y-10 lg:gap-y-12">
                {articles.slice(1).map((article, index) => (
                  <div
                    className="flex flex-col w-full lg:w-4/5 gap-y-2"
                    key={index}
                  >
                    <div className="w-full lg:w-full h-60 lg:h-72">
                      <img
                        src={`https://api.kaylamrealty.com/images/${article.file}`}
                        alt={article.titulo_es}
                        className="w-full h-full"
                      />
                    </div>

                    <div>
                      <h5 className="text-xs">
                        {moment(article.created_at).format("DD/MM/YYYY")}
                      </h5>

                      {/* <h5 className="text-xs">07/09/2023</h5> */}
                    </div>

                    <div>
                      <button
                        onClick={() =>
                          handleShow(
                            article[`titulo_${i18n.language}`],
                            article.id
                          )
                        }
                      >
                        <h2 className="text-xl font-semibold text-left">
                          {t(article[`titulo_${i18n.language}`])}
                        </h2>
                      </button>
                    </div>

                    <div className="flex items-center gap-x-4">
                      <div className="flex items-center justify-center w-8 h-8 rounded-full lg:h-14 lg:w-14">
                        <img
                          src={Profile}
                          className="w-full h-full rounded-full"
                          alt=""
                        />
                      </div>
                      <h4 className="font-bold">Lamia Badache</h4>
                    </div>

                    <div
                      className="text-justify text-gris"
                      dangerouslySetInnerHTML={{
                        __html:
                          t(article[`contenido_${i18n.language}`]).substring(
                            0,
                            MAX_CARACTERES
                          ) + "...",
                      }}
                    ></div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col lg:hidden gap-y-10 lg:gap-y-12">
                {articles.map((article, index) => (
                  <div
                    className="flex flex-col w-full lg:w-4/5 gap-y-2"
                    key={index}
                  >
                    <div className="w-full lg:w-full h-60 lg:h-72">
                      <img
                        src={`https://api.kaylamrealty.com/images/${article.file}`}
                        alt={article.titulo_es}
                        className="w-full h-full"
                      />
                    </div>

                    <div>
                      <h5 className="text-xs">
                        {moment(article.created_at).format("DD/MM/YYYY")}
                      </h5>

                      {/* <h5 className="text-xs">07/09/2023</h5> */}
                    </div>

                    <div>
                      <button
                        onClick={() =>
                          handleShow(
                            article[`titulo_${i18n.language}`],
                            article.id
                          )
                        }
                      >
                        <h2 className="text-xl font-semibold text-left">
                          {t(article[`titulo_${i18n.language}`])}
                        </h2>
                      </button>
                    </div>

                    <div className="flex items-center gap-x-4">
                      <div className="flex items-center justify-center w-8 h-8 rounded-full lg:h-14 lg:w-14">
                        <img
                          src={Profile}
                          className="w-full h-full rounded-full"
                          alt=""
                        />
                      </div>
                      <h4 className="font-bold">Lamia Badache</h4>
                    </div>

                    <div
                      className="text-justify text-gris"
                      dangerouslySetInnerHTML={{
                        __html:
                          t(article[`contenido_${i18n.language}`]).substring(
                            0,
                            MAX_CARACTERES
                          ) + "...",
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-center w-full h-20 mt-10 lg:mt-20">
              <div>
                <Link
                  to="/"
                  className="flex items-center justify-center w-48 h-10 text-base text-white rounded bg-gris-blanco"
                >
                  Load more blog posts
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-col hidden w-2/6 h-full lg:flex">
            <div className="flex items-center h-10">
              <h2 className="text-3xl font-semibold font-minion">
                Related Posts
              </h2>
            </div>
            <div className="flex w-full h-auto mt-10 ">
              <div className="w-1 h-auto mr-6 opacity-50 bg-gris"></div>
              <div className="flex flex-col gap-y-8">
                {randomPost.slice(1).map((article, index) => (
                  <div className="flex w-full h-20" key={index}>
                    <div className="flex w-1/3 mr-2">
                      <img
                        src={`https://api.kaylamrealty.com/images/${article.file}`}
                        className="w-full rounded"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col w-2/3 pr-4">
                      <button
                        onClick={() =>
                          handleShow(
                            article[`titulo_${i18n.language}`],
                            article.id
                          )
                        }
                      >
                        <h2 className="text-base text-left">
                          {t(article[`titulo_${i18n.language}`])}
                        </h2>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
