import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import ImageGallery from "react-image-gallery";
import { useApi } from "../Context/ApiContext";

export default function Carousel() {
  const { i18n } = useTranslation();
  const apiData = useApi(); // Utiliza el hook useApi del contexto
  const [houses, setHouses] = useState([]);
  const { ref } = useParams();
  const [images, setImages] = useState([]);

  const filteredHouse = houses.filter(
    (h) => h.ref.toString() === ref.toString()
  )[0];

  useEffect(() => {
    const lang = Cookies.get("i18next") || "en"; // default to English

    if (apiData) {
      const fetchedHouses = apiData || [];
      setHouses(fetchedHouses);
    }

    Cookies.set("i18next", lang);
  }, [i18n.language, apiData]);

  useEffect(() => {
    const lang = Cookies.get("i18next") || "en"; // default to English

    const filteredHouse = houses.filter(
      (h) => h.ref.toString() === ref.toString()
    )[0];

    if (
      filteredHouse &&
      Object.keys(filteredHouse).some((key) => key.includes("foto"))
    ) {
      const imageArray = Object.keys(filteredHouse)
        .filter((key) => key.includes("foto"))
        .slice(1)
        .map((key) => ({
          original: filteredHouse[key],
          thumbnail: filteredHouse[key],
        }));

      setImages(imageArray);
      Cookies.set("i18next", lang);
    }
  }, [filteredHouse, houses, ref]);

  return (
    <>
      <div className="w-full mx-auto my-0 lg:w-10/12">
        <ImageGallery
          items={images}
          showNav={true}
          lazyLoad={true}
          autoPlay={false}
          showIndex={true}
          useBrowserFullscreen={true}
          thumbnailPosition={"bottom"}
          disableKeyDown={false}
        />
      </div>
    </>
  );
}
