import logos from "../../assets/logo/Plan de travail 6_2KAYLAM.png";
// import Navbar from "../../Components/Navbar";
import bed from "../../assets/images/bed.png";
import bath from "../../assets/images/bath.png";
import Phone from "../../assets/icons/Group 34845.svg";
import Email from "../../assets/icons/Group 34846.svg";
// import Begur from "../../assets/images/localidades/begur.png";
import Consultant from "../../assets/icons/kays.jpeg";
// import wasa from "../../assets/images/wasa.png";
// import Whatsapp from "../../Components/Whatsapp";
import YouTube from "react-youtube"; // Asegúrate de tener esta importación

// import video from "../../assets/icons/video.png";
import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
// import { useLocation } from "react-router-dom";
import Carousel from "../../Components/Carousel";
import { useTranslation } from "react-i18next";
import { FaHeart } from "react-icons/fa";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import axios from "axios";
import fotoA from "../../assets/energy/A.png";
import fotoB from "../../assets/energy/B.png";
import fotoC from "../../assets/energy/C.png";
import fotoD from "../../assets/energy/D.png";
import fotoE from "../../assets/energy/E.png";
import fotoF from "../../assets/energy/F.png";
import fotoG from "../../assets/energy/G.png";
import React from "react";
import ContactForm from "../../Components/Contact";
import Footer from "../../Components/Footer";
import Recent from "../../Components/Recent";
import { useImageContext } from "../../Components/Context";
// import html2canvas from "html2canvas";
import tour from "../../assets/images/tour.png";
// import logo from "../../assets/logo/Plan de travail 6_2KAYLAM.png";
import {
  Document,
  Page,
  View,
  Image,
  Text,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { useApi } from "../../Context/ApiContext";
import Navbar from "../../Components/Navbar";

// Definir estilos para el PDF
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
  },
  section: {
    padding: 10,
    flexGrow: 1,
  },
  logo: {
    width: 150,
    height: 150,
    marginBottom: 10,
  },
  texto: {
    display: "flex",
    width: "100%",
    paddingHorizontal: 10,
  },
  image: {
    width: 300,
    height: 200,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    textAlign: "left",
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    textAlign: "left",
    marginBottom: 10,
    fontWeight: "bold",
  },
  char: {
    paddingHorizontal: 10,
  },
  details: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    rowGap: 10,
    height: 50,
    fontSize: 12,
  },
});
const translations = [
  { lang: "es", index: "1" },
  { lang: "en", index: "2" },
  { lang: "de", index: "3" },
  { lang: "fr", index: "4" },
  { lang: "nl", index: "5" },
  { lang: "ru", index: "7" },
];
export default function NewHouse() {
  const { t, i18n } = useTranslation();
  const [pdfGenerated, setPdfGenerated] = useState(false);

  const { lang, ref } = useParams();
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const apiData = useApi(); // Usa el hook useApi para obtener los datos de la API
  const [house, setHouse] = useState("");
  const [houses, setHouses] = useState([]);

  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);
  const { images, setImages } = useImageContext();

  const [imagesUrl, setImagesUrl] = useState([]); // Correcta declaración de imagesUrl como una variable de estado

  const urlImages = images.slice(1).map(
    (image) => image.original // Obtiene la URL de la primera imagen de cada objeto image
  );

  useEffect(() => {
    if (apiData) {
      const fetchedHouses = apiData || [];
      setHouses(fetchedHouses);
    }
  }, [apiData]);

  // ... (your existing code)
  const generatePDF = useCallback(async () => {
    try {
      console.log("Starting PDF generation...");

      if (!house) {
        console.error("House data is undefined.");
        return;
      }
      const features = [
        { key: "Aire_central", label: "Aire_Acond._Central" },
        { key: "Aire", label: "Aire_Acondicionado" },
        { key: "Alarma", label: "Alarma" },
        { key: "Apart_Separado", label: "Apart._Separado" },
        { key: "Armario_empotrado", label: "Armarios_empotrados" },
        { key: "Ascensor", label: "Ascensor" },
        { key: "Barbacoa", label: "Barbacoa" },
        { key: "Balcon", label: "Balcón" },
        { key: "Bomba", label: "Bomba_frío_y_calor" },
        { key: "Buhardilla", label: "Buhardilla" },
        { key: "Calefaccion", label: "Calefacción" },
        { key: "Calefaccion_C", label: "Calefacción_central" },
        { key: "Chimenea", label: "Chimenea" },
        { key: "Deposito", label: "Deposito_Agua" },
        { key: "Descalcificador", label: "Descalcificador" },
        { key: "Gasciudad", label: "Gas_Ciudad" },
        { key: "Gimnasio", label: "Gimnasio" },
        { key: "Hab_Juegos", label: "Hab._Juegos" },
        { key: "Jacuzzi", label: "Jacuzzi" },
        { key: "Jardin", label: "Jardín" },
        { key: "Lavanderia", label: "Lavandería" },
        { key: "Muebles", label: "Muebles" },
        { key: "Pergola", label: "Pérgola" },
        { key: "Piscina_C", label: "Piscina_Comunitaria" },
        { key: "Piscina_P", label: "Piscina_Propia" },
        { key: "Sauna", label: "Sauna" },
        { key: "Sotano", label: "Sótano" },
        { key: "Terraza", label: "Terraza" },
        { key: "Trastero", label: "Trastero" },
        { key: "Video_P", label: "Videoportero" },
        { key: "Autobuses", label: "Autobuses" },
        { key: "Arboles", label: "Árboles" },
        { key: "Centros_C", label: "Centros_comerciales" },
        { key: "Colegios", label: "Colegios" },
        { key: "Costa", label: "Costas" },
        { key: "Golf", label: "Golf" },
        { key: "Hospitales", label: "Hospitales" },
        { key: "Metro", label: "Metro" },
        { key: "Montana", label: "Montaña" },
        { key: "Primera_L", label: "Primera_Línea" },
        { key: "Rural", label: "Rural" },
        { key: "Tranvia", label: "Tranvía" },
        { key: "Tren", label: "Tren" },
        { key: "Urbanizacion", label: "Urbanización" },
        { key: "Vallado", label: "Vallado" },
        { key: "Vistas", label: "Vistas_al_mar" },
        { key: "Zona", label: "Zona_de_paso" },
        { key: "Zonas", label: "Zonas_infantiles" },
      ];

      const MyDocument = (
        <Document>
          <Page style={styles.page}>
            <View style={styles.section}>
              <Image style={styles.logo} src={logos} />
            </View>
            <View style={styles.texto}>
              <Text style={styles.title}>{house.Titulo}</Text>
              <Text style={styles.text}>
                {t("Referencia")} : {house.ref}
              </Text>
              <Text style={styles.text}>
                {t("Ciudad")} : {house.Ciudad}
              </Text>
              <Text style={styles.text}>
                {t("Habitaciones")} : {house.Habitaciones}
              </Text>
              <Text style={styles.text}>
                {t("Baños")} : {house.Baños}{" "}
              </Text>
              <Text style={styles.text}>
                {t("Construida")} : {house.Construida}
              </Text>
              <Text style={styles.text}>
                {t("Parcela")} : {house.Parcela}{" "}
              </Text>
              <Text style={styles.text}>
                {t("Precio")} : {house.Precio}
              </Text>

              {imagesUrl.slice(0, 4).map((imageUrl, index) => (
                <Image src={imageUrl} key={index} style={styles.image} />
              ))}

              {/* <Text style={styles.text}>Descripcion:</Text>
          <Text style={styles.text}>Caracterisitcas</Text> */}
            </View>
            <View style={styles.char}>
              <Text>{t("Caracteristicas_Propiedad")}</Text>
              <View style={styles.details}>
                {features.map((feature) => (
                  <Text key={feature.key}>
                    {house[feature.key] === 1 ? `${t(feature.label)}, ` : ""}
                  </Text>
                ))}
              </View>
            </View>
          </Page>
        </Document>
      );

      // Convertir el documento PDF en formato Blob
      const blob = await pdf(MyDocument).toBlob();

      console.log("PDF generation successful.");

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${house.ref} - ${lang}.pdf`; // Set the file name
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error during PDF generation:", error);
      console.trace(); // Agrega una traza completa para rastrear el error
    }
    setPdfGenerated(true);
  }, [house, imagesUrl, t, lang]);

  useEffect(() => {
    if (!Array.isArray(urlImages)) {
      console.error("Invalid or missing urlImages data.");
      return;
    }
    if (imagesLoaded) {
      const formData = new FormData();

      // Agregar las URLs de las imágenes al FormData
      urlImages.forEach((imageUrl, index) => {
        formData.append(`image${index + 1}`, imageUrl);
      });

      axios
        .post("https://back.kaylamrealty.com/api/load-image", formData)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            // Tu backend ha cargado las imágenes y devuelto las respuestas
            const imageResponses = response.data;
            console.log(imageResponses);

            // Obtener solo las URL de cada elemento
            const imageUrls = imageResponses.map((response) => {
              // Agregar el encabezado data:image/jpeg;base64, si no está presente
              const imageUrl = response.body.startsWith(
                "data:image/jpeg;base64,"
              )
                ? response.body
                : `data:image/jpeg;base64,${response.body}`;
              return imageUrl;
            });

            setImagesUrl(imageUrls);

            // Ahora que las imágenes se han cargado correctamente,
            // puedes mostrar los logs y generar el PDF aquí
            console.log(imageUrls); // Log de las URLs de las imágenes
          } else {
            console.error(
              "Error en la respuesta del servidor:",
              response.status
            );
          }
        })
        .catch((error) => {
          console.error("Error al cargar las imágenes:", error);
        });
    }

    if (imagesLoaded && house && !pdfGenerated) {
      setPdfGenerated(true); // Añade esta línea para marcar que el PDF se ha generado
    }
  }, [imagesLoaded, urlImages, generatePDF, house, pdfGenerated]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  const energyPhotos = {
    A: fotoA,
    B: fotoB,
    C: fotoC,
    D: fotoD,
    E: fotoE,
    F: fotoF,
    G: fotoG,
  };

  const [selectedCity, setSelectedCity] = useState(null);

  const foto = energyPhotos[house.Energia] || null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Lógica para el cambio de idioma
    const index = translations.findIndex((t) => t.lang === i18n.language);
    setCurrentLanguageIndex(index);
  }, [i18n.language]);

  const cityData = useMemo(
    () => [
      {
        title: "Lloret de Mar",
        text: [t("Lloret_1")].concat(t("Lloret_2"), t("Lloret_3")),
      },
      {
        title: "Begur",
        text: [t("Begur")].concat(t("Begur_1"), t("Begur_2"), t("Begur_3")),
      },
      {
        title: "Calonge",
        text: [t("Calonge")].concat(t("Calonge_1"), t("Calonge_2")),
      },
      {
        title: "Platja d'Aro",
        text: [t("Platja")].concat(
          t("Platja_1"),
          t("Platja_2"),
          t("Platja_3"),
          t("Platja_4"),
          t("Platja_5")
        ),
      },
      {
        title: "Santa Cristina d'Aro",
        text: [t("Santa")],
      },
      {
        title: "Tossa de Mar",
        text: [t("Calonge_1")].concat(t("Calonge_2")),
      },
      {
        title: "Caldes de Malavella",
        text: [t("Calonge_1")].concat(t("Calonge_2")),
      },
      {
        title: "Blanes",
        text: [t("Blanes")].concat(
          t("Blanes_1"),
          t("Blanes_2"),
          t("Blanes_3"),
          t("Blanes_4")
        ),
      },
      {
        title: "Calonge",
        text: [t("Calonge_1")].concat(t("Calonge_2")),
      },
      // ... Otras ciudades
    ],
    [t]
  );

  // const cityData = [
  //   {
  //     title: "Lloret de Mar",
  //     text: [t("Lloret_1")].concat(t("Lloret_2"), t("Lloret_3")),
  //   },
  //   {
  //     title: "Begur",
  //     text: [t("Begur")].concat(t("Begur_1"), t("Begur_2"), t("Begur_3")),
  //   },
  //   {
  //     title: "Calonge",
  //     text: [t("Calonge")].concat(t("Calonge_1"), t("Calonge_2")),
  //   },
  //   {
  //     title: "Platja d'Aro",
  //     text: [t("Platja")].concat(
  //       t("Platja_1"),
  //       t("Platja_2"),
  //       t("Platja_3"),
  //       t("Platja_4"),
  //       t("Platja_5")
  //     ),
  //   },
  //   {
  //     title: "Santa Cristina d'Aro",
  //     text: [t("Santa")],
  //   },
  //   {
  //     title: "Tossa de Mar",
  //     text: [t("Calonge_1")].concat(t("Calonge_2")),
  //   },
  //   {
  //     title: "Caldes de Malavella",
  //     text: [t("Calonge_1")].concat(t("Calonge_2")),
  //   },
  //   {
  //     title: "Blanes",
  //     text: [t("Blanes")].concat(
  //       t("Blanes_1"),
  //       t("Blanes_2"),
  //       t("Blanes_3"),
  //       t("Blanes_4")
  //     ),
  //   },
  //   {
  //     title: "Calonge",
  //     text: [t("Calonge_1")].concat(t("Calonge_2")),
  //   },
  //   // Add more cities and their corresponding text here
  // ];

  useEffect(() => {
    const filteredHouse = houses.filter(
      (h) => h.ref.toString() === ref.toString()
    )[0];
    if (!filteredHouse) {
      console.error("Filtered house data is undefined.");
      return;
    }
    if (filteredHouse) {
      if (Object.keys(filteredHouse).some((key) => key.includes("foto"))) {
        const imageArray = Object.keys(filteredHouse)
          .filter((key) => key.includes("foto"))
          .map((key) => ({
            original: filteredHouse[key],
          }));

        setImages(imageArray);

        setImagesLoaded(true);

        // You may want to set Cookies here if needed
      }
      setHouse({
        Habitaciones: filteredHouse.habdobles,
        Titulo:
          filteredHouse["titulo" + translations[currentLanguageIndex].index],
        Descripcion_Casa: filteredHouse[
          "descrip" + translations[currentLanguageIndex].index
        ]
          .toString()
          .replaceAll("~", "")
          .substring(20),
        ref: filteredHouse.ref,
        Baños: filteredHouse.banyos,
        Price: parseInt(filteredHouse.precioinmo),
        Precio:
          parseInt(filteredHouse.precioinmo).toLocaleString("es-ES") + " €",
        Construida: filteredHouse.m_cons,
        Parcela: filteredHouse.m_parcela,
        Energia: filteredHouse.energialetra,
        Aire_central: parseInt(filteredHouse.airecentral),
        Aire: parseInt(filteredHouse.aire_con),
        Arboles: parseInt(filteredHouse.arboles),
        Autobuses: parseInt(filteredHouse.autobuses),
        Alarma: parseInt(filteredHouse.alarma),
        Apart_Separado: parseInt(filteredHouse.apartseparado),
        Armario_emportado: parseInt(filteredHouse.arma_empo),
        Antiguedad: parseInt(filteredHouse.antiguedad),
        Ascensor: parseInt(filteredHouse.ascensor),
        Balcon: parseInt(filteredHouse.balcon),
        Barbacoa: parseInt(filteredHouse.barbacoa),
        Bomba: parseInt(filteredHouse.bombafriocalor),
        Buhardilla: parseInt(filteredHouse.buhardilla),
        Calefaccion: parseInt(filteredHouse.calefaccion),
        Ciudad: filteredHouse.ciudad,
        Tour: filteredHouse.tour,
        Calefaccion_C: parseInt(filteredHouse.calefacentral),
        Chimenea: parseInt(filteredHouse.chimenea),
        Colegios: parseInt(filteredHouse.colegios),
        Costa: parseInt(filteredHouse.costa),
        Centros_C: parseInt(filteredHouse.centros_comerciales),
        Deposito: parseInt(filteredHouse.depoagua),
        Descalcificador: parseInt(filteredHouse.descalcificador),
        Gasciudad: parseInt(filteredHouse.gasciudad),
        Gimnasio: parseInt(filteredHouse.gimnasio),
        Golf: parseInt(filteredHouse.golf),
        Hab_Juegos: parseInt(filteredHouse.habjuegos),
        Hospitales: parseInt(filteredHouse.hospitales),
        Jacuzzi: parseInt(filteredHouse.jacuzzi),
        Jardin: parseInt(filteredHouse.jardin),
        Lavanderia: parseInt(filteredHouse.lavanderia),
        Muebles: parseInt(filteredHouse.muebles),
        Metro: parseInt(filteredHouse.metro),
        Montana: parseInt(filteredHouse.montana),
        Pergola: parseInt(filteredHouse.pergola),
        Ibi: parseInt(filteredHouse.ibi),
        Comunidad: parseInt(filteredHouse.comunidadincluida),
        Piscina_P: parseInt(filteredHouse.piscina_prop),
        Piscina_C: parseInt(filteredHouse.piscina_com),
        Primera_L: parseInt(filteredHouse.primera_line),
        Sauna: parseInt(filteredHouse.sauna),
        Rural: parseInt(filteredHouse.rural),
        Sotano: parseInt(filteredHouse.sotano),
        Trastero: parseInt(filteredHouse.trastero),
        Tranvia: parseInt(filteredHouse.tranvia),
        Tren: parseInt(filteredHouse.tren),
        Terraza: parseInt(filteredHouse.terraza),
        Urbanizacion: parseInt(filteredHouse.urbanizacion),
        Vallado: parseInt(filteredHouse.vallado),
        Video_P: parseInt(filteredHouse.video_port),
        Vistas: parseInt(filteredHouse.vistasalmar),
        Video: String(filteredHouse.video1),
        Zona: parseInt(filteredHouse.zona),
        Zonas: parseInt(filteredHouse.zonas_infantiles),
      });
      if (
        filteredHouse &&
        filteredHouse.ciudad &&
        filteredHouse.ciudad.length > 0
      ) {
        const cityName = filteredHouse.ciudad[0].toString();
        const city = cityData.find((city) => city.title === cityName);
        setSelectedCity(city);
      } else {
        setSelectedCity(null);
      }
    } else {
      setHouse({});
    }
  }, [houses, ref, currentLanguageIndex, cityData, setImages]);

  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const savedFavorites = Cookies.get("favorites");
    if (savedFavorites) {
      setFavorites(JSON.parse(savedFavorites));
    }
  }, []);

  function handleFavorite(ref) {
    const stringRef = ref.toString();
    const updatedFavorites = [...favorites];

    if (updatedFavorites.includes(stringRef)) {
      const index = updatedFavorites.indexOf(stringRef);
      updatedFavorites.splice(index, 1);
    } else {
      updatedFavorites.push(stringRef);
    }

    setFavorites(updatedFavorites);
    Cookies.set("favorites", JSON.stringify(updatedFavorites));
  }
  return (
    <>
      {/* <div className="absolute z-50 items-center justify-end w-full ml-6 lg:flex bg-marino lg:ml-24 lg:h-36 ">
        <LanguageComponent />
      </div> */}
      <div className="relative flex flex-col w-full min-h-screen">
        <Navbar />

        <div className="w-full h-auto ">
          <div className="w-full h-auto">
            <div className="w-full min-h-screen p-4 mt-10 ">
              <Carousel />

              <div className="flex flex-col w-full mt-10 lg:mt-20 gap-y-14 lg:w-full lg:mx-auto lg:my-0">
                <div className="w-10/12 mx-auto my-0">
                  <div className="flex flex-row w-full h-auto lg:flex-row lg:justify-between lg:items-center lg:h-auto">
                    <div className="flex items-center w-11/12 h-full text-base font-semibold lg:text-4xl font-minion">
                      <h2>{house.Titulo}</h2>
                    </div>
                    <div className="flex flex-row-reverse items-center w-1/12 h-full">
                      <button
                        className="z-30 h-10 ml-8"
                        onClick={() => handleFavorite(house.ref)}
                      >
                        {house.ref && (
                          <FaHeart
                            size={44}
                            className="hidden lg:block"
                            style={{
                              color: favorites.includes(house.ref.toString())
                                ? "red"
                                : "grey",
                            }}
                          />
                        )}
                        {house.ref && (
                          <FaHeart
                            size={24}
                            className="block lg:hidden"
                            style={{
                              color: favorites.includes(house.ref.toString())
                                ? "red"
                                : "grey",
                            }}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="flex w-full font-sans text-sm font-semibold gap-x-2 text-gris lg:text-2xl">
                    <h2> {t("Referencia")} :</h2>
                    <h2 className="text-black ">{house.ref}</h2>
                  </div>
                  <div className="flex w-full font-sans text-sm font-semibold gap-x-2 lg:text-2xl lg:mt-2 text-gris-blanco">
                    <h2 className="text-gris">{t("Precio")} :</h2>
                    <h2>{house.Precio}</h2>
                  </div>
                  {imagesLoaded && (
                    <div className="mt-4">
                      <button
                        onClick={() => {
                          generatePDF();
                          setPdfGenerated(true);
                        }}
                      >
                        Generar PDF
                      </button>
                    </div>
                  )}

                  {/* {house.Video && house.Video !== "" && (
                    <div className="flex items-center w-full font-sans text-sm font-semibold gap-x-2 lg:text-2xl lg:mt-2 text-gris-blanco">
                      <a
                        href={`https://www.youtube.com/watch?v=${house.Video}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={video} className="w-10" alt="" />
                      </a>
                    </div>
                  )} */}

                  {house.Tour && house.Tour.length > 1 && (
                    <div className="mt-4">
                      <a
                        href={house.Tour}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={tour} alt="tour" className="w-20" />
                      </a>
                    </div>
                  )}

                  <div className="flex justify-between w-full mt-4 gap-x-2 lg:mt-12">
                    <div className="w-auto">
                      <div className="text-sm lg:text-2xl text-gris">
                        <h2>{t("Habitaciones")}</h2>
                      </div>
                      <div className="flex items-center">
                        <div className="mr-2 text-sm lg:text-lg">
                          <h2>{house.Habitaciones}</h2>
                        </div>
                        <div>
                          <img src={bed} alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="w-auto">
                      <div className="text-sm lg:text-2xl text-gris">
                        <h2>{t("Baños")}</h2>
                      </div>
                      <div className="flex items-center">
                        <div className="mr-2 text-sm lg:text-lg">
                          <h2>{house.Baños}</h2>
                        </div>
                        <div>
                          <img src={bath} alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="w-auto">
                      <div className="text-sm lg:text-2xl text-gris">
                        <h2>
                          {" "}
                          <h2>{t("Construida")}</h2>
                        </h2>
                      </div>
                      <div className="flex">
                        <div className="mr-2 text-sm lg:text-lg">
                          <h2>
                            {parseFloat(house.Construida).toFixed(0)} m
                            <sup>2</sup>
                          </h2>
                        </div>
                      </div>
                    </div>

                    <div className="w-auto">
                      <div className="text-sm lg:text-2xl text-gris">
                        <h2>{t("Parcela")}</h2>
                      </div>
                      <div className="flex">
                        <div className="mr-2 text-sm lg:text-lg">
                          <h2>
                            {house.Parcela}m<sup>2</sup>
                          </h2>
                        </div>
                      </div>
                    </div>

                    {/* <div className="">
                    <div className="text-sm lg:text-xl text-gris">
                      <h2>Parking</h2>
                    </div>
                    <div className="flex">
                      <div className="mr-2 text-sm lg:text-lg">
                        <h2>1 Indoor</h2>
                      </div>
                    </div>
                  </div> */}
                  </div>
                  <div className="flex flex-col w-full mt-6 gap-y-2 lg:flex-row lg:mt-12 ">
                    <div className="mr-4 lg:text-xl">
                      <h2>{t("Detalles")}</h2>
                    </div>
                    <div className="text-base text-justify lg:text-xl text-gris">
                      <h2>{house.Descripcion_Casa}</h2>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-10/12 h-auto mx-auto my-0 gap-y-12">
                  <div className="w-full h-10 text-2xl font-bold text-center lg:text-4xl font-minion">
                    <h2>{t("Caracteristicas_Propiedad")}</h2>
                  </div>

                  <div className="flex flex-wrap items-center justify-center text-xl">
                    {house.Aire_central === 1 ? (
                      <h2 className="pr-1">{t("Aire_Acond._Central")},</h2>
                    ) : (
                      ""
                    )}

                    {house.Aire === 1 ? (
                      <h2 className="pr-1">{t("Aire_Acondicionado")},</h2>
                    ) : (
                      ""
                    )}
                    <h2 className="text-marino">
                      {house.Alarma === 1 ? (
                        <h2 className="pr-1">{t("Alarma")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Apart_Separado === 1 ? (
                        <h2 className="pr-1">{t("Apart._Separado")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Armario_empotrado === 1 ? (
                        <h2 className="pr-1">{t("Armarios_empotrados")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Ascensor === 1 ? (
                        <h2 className="pr-1">{t("Ascensor")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    {house.Barbacoa === 1 ? (
                      <h2 className="pr-1">{t("Barbacoa")},</h2>
                    ) : (
                      ""
                    )}
                    <h2 className="text-marino">
                      {house.Balcon === 1 ? (
                        <h2 className="pr-1">{t("Balcón")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Bomba === 1 ? (
                        <h2 className="pr-1">{t("Bomba_frío_y_calor")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Buhardilla === 1 ? (
                        <h2 className="pr-1">{t("Buhardilla")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Calefaccion === 1 ? (
                        <h2 className="pr-1">{t("Calefacción")},</h2>
                      ) : (
                        t("")
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Calefaccion_C === 1 ? (
                        <h2 className="pr-1">{t("Calefacción_central")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Comunidad === 1 ? (
                        <h2 className="pr-1">{t("Comunidad")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Chimenea === 1 ? (
                        <h2 className="pr-1">{t("Chimenea")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Deposito === 1 ? (
                        <h2 className="pr-1">{t("Deposito_Agua")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Descalcificador === 1 ? (
                        <h2 className="pr-1">{t("Descalcificador")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2>
                      {house.Gasciudad === 1 ? (
                        <h2 className="pr-1">{t("Gas_Ciudad")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Gimnasio === 1 ? (
                        <h2 className="pr-1">{t("Gimnasio")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Hab_Juegos === 1 ? (
                        <h2 className="pr-1">{t("Hab._Juegos")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Jacuzzi === 1 ? (
                        <h2 className="pr-1">{t("Jacuzzi")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Jardin === 1 ? (
                        <h2 className="pr-1">{t("Jardín")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Lavanderia === 1 ? (
                        <h2 className="pr-1">{t("Lavandería")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Muebles === 1 ? (
                        <h2 className="pr-1">{t("Muebles")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>

                    <h2 className="text-marino">
                      {house.Pergola === 1 ? (
                        <h2 className="pr-1">{t("Pérgola")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Piscina_C === 1 ? (
                        <h2 className="pr-1">{t("Piscina_Comunitaria")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Piscina_P === 1 ? (
                        <h2 className="pr-1">{t("Piscina_Propia")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Sauna === 1 ? (
                        <h2 className="pr-1">{t("Sauna")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Sotano === 1 ? (
                        <h2 className="pr-1">{t("Sótano")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Terraza === 1 ? (
                        <h2 className="pr-1">{t("Terraza")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Trastero === 1 ? (
                        <h2 className="pr-1">{t("Trastero")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Video_P === 1 ? (
                        <h2 className="pr-1">{t("Videoportero")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Autobuses === 1 ? (
                        <h2 className="pr-1">{t("Autobuses")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Arboles === 1 ? (
                        <h2 className="pr-1">{t("Árboles")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Centros_C === 1 ? (
                        <h2 className="pr-1">{t("Centros_comerciales")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Colegios === 1 ? (
                        <h2 className="pr-1">{t("Colegios")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Costa === 1 ? (
                        <h2 className="pr-1">{t("Costas")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Golf === 1 ? (
                        <h2 className="pr-1">{t("Golf")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Hospitales === 1 ? (
                        <h2 className="pr-1">{t("Hospitales")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Metro === 1 ? (
                        <h2 className="pr-1">{t("Metro")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Montana === 1 ? (
                        <h2 className="pr-1">{t("Montaña")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Primera_L === 1 ? (
                        <h2 className="pr-1">{t("Primera_Línea")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Rural === 1 ? (
                        <h2 className="pr-1">{t("Rural")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Tranvia === 1 ? (
                        <h2 className="pr-1">{t("Tranvía")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Tren === 1 ? (
                        <h2 className="pr-1">{t("Tren")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Urbanizacion === 1 ? (
                        <h2 className="pr-1">{t("Urbanización")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Vallado === 1 ? (
                        <h2 className="pr-1">{t("Vallado")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Vistas === 1 ? (
                        <h2 className="pr-1">{t("Vistas_al_mar")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Zona === 1 ? (
                        <h2 className="pr-1">{t("Zona_de_paso")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                    <h2 className="text-marino">
                      {house.Zonas === 1 ? (
                        <h2 className="pr-1">{t("Zonas_infantiles")},</h2>
                      ) : (
                        ""
                      )}
                    </h2>
                  </div>

                  {house.Antiguedad ? (
                    <div className="flex flex-col items-center justify-between w-full h-auto gap-y-6 ">
                      <h2 className="text-2xl font-bold lg:text-4xl font-minion">
                        {t("Antiguedad")}
                      </h2>
                      <h2 className="pr-1 text-marino">{house.Antiguedad},</h2>
                    </div>
                  ) : (
                    ""
                  )}
                  {house.Ibi > 0 && (
                    <div className="flex flex-col items-center justify-between w-full h-auto gap-y-6 ">
                      <h2 className="text-2xl font-bold lg:text-4xl font-minion">
                        {t("IBI")}
                      </h2>
                      <h2 className="pr-1 text-marino">{house.Ibi},</h2>
                    </div>
                  )}
                  <div className="flex flex-col items-center justify-between w-full h-auto gap-y-6 ">
                    <h2 className="text-2xl font-bold lg:text-4xl font-minion">
                      {t("Certificado")}
                    </h2>
                    {foto && <img src={foto} clas alt="Foto Energía" />}
                  </div>
                  {house.Video && (
                    <div className="flex flex-col w-full">
                      <h2 className="text-2xl font-bold text-center lg:text-4xl font-minion">
                        Video
                      </h2>
                      <div className="block lg:hidden">
                        <YouTube
                          videoId={house.Video}
                          iframeClassName="w-full h-48"
                          className="mt-10 "
                        />
                      </div>
                      <div className="hidden lg:block">
                        <YouTube videoId={house.Video} className="mt-10 " />
                      </div>
                    </div>
                  )}
                </div>
                {selectedCity && (
                  <div className="flex flex-col w-10/12 mx-auto my-0 gap-y-8">
                    <div className="w-full h-10 text-2xl font-bold text-center lg:text-4xl font-minion">
                      <h2>Location</h2>
                    </div>

                    <div className="flex flex-col w-full gap-y-6">
                      <div className="flex flex-col w-full mt-10 gap-y-6">
                        <h2 className="text-2xl font-semibold lg:text-2xl">
                          {selectedCity.title}
                        </h2>
                        <h3 className="text-justify lg:text-xl">
                          {selectedCity.text.map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                              <br />
                            </React.Fragment>
                          ))}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex flex-col w-full gap-y-8">
                  <div className="w-full h-10 text-2xl font-bold text-center font-minion lg:text-4xl">
                    <h2>{t("Pongase_Contacto")}</h2>
                  </div>

                  <h4 className="text-xl text-center lg:text-xl">
                    {/* {t("Referencia")} : {house.ref} */}
                  </h4>

                  <div className="flex flex-col w-full lg:items-center lg:justify-center gap-y-6">
                    <div className="flex items-center justify-center w-full mt-10">
                      <img
                        className="w-1/4 rounded-full"
                        src={Consultant}
                        alt=""
                      />
                    </div>

                    <div className="flex flex-col items-center w-full gap-y-4">
                      {/* <h2 className="text-xl lg:text-3xl">Isabella Rodriguez</h2>

                      <h3 className="text-gray-500 lg:text-xl text-md">
                        Managing Director
                      </h3> */}

                      <div className="flex flex-col items-center w-full pl-12 lg:mt-5 gap-y-8">
                        <div className="flex items-center mx-auto my-0 w-80 gap-x-4">
                          <img src={Phone} alt="" />
                          <h2>Phone : (+34) 687 166 880</h2>
                        </div>

                        <div className="flex items-center mx-auto my-0 w-80 gap-x-4">
                          <img src={Email} alt="" />
                          <h2>Email: info@kaylamrealty.com</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full mt-10 gap-y-6">
                  <Recent localidad={house.Ciudad} Price={house.Price} />
                </div>

                <ContactForm Ref={ref} />

                {/* <div className="flex flex-col w-full mt-20 gap-y-6">
                  <div className="w-full h-10 text-3xl text-center ">
  <h2 >
    Similar houses
  </h2>
</div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
