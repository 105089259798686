import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import Locations from "../../Components/Locations";
import Whatsapp from "../../Components/Whatsapp";
// import Sidebar from "../../Components/Sidebar";
import { useTranslation } from "react-i18next";
// import LanguageComponent from "../../Components/Language";

export default function Rent() {
  const { t } = useTranslation();

  return (
    <>
      {/* <div className="absolute z-30 flex items-center justify-end w-11/12 h-24 ml-6 bg-marino lg:ml-24 lg:h-1/5 ">
        <LanguageComponent />
      </div> */}
      <div className="w-screen h-screen mb-32 bg-marino">
        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>
        {/* <div className="w-full h-24 lg:hidden">
          <Sidebar />
        </div>{" "} */}
        <main className="relative flex flex-col w-full h-full">
          <Navbar />
          <section className="relative flex w-full h-full ">
            <div className="absolute z-0 w-full h-full bg-center bg-no-repeat bg-cover brightness-75 bg-Favorit"></div>
            <div className="z-10 flex flex-col justify-center w-full">
              <header className="flex flex-col items-center justify-evenly h-3/4 ">
                <div className="flex items-center justify-center w-full sm:w-1/2 lg:w-5/6 h-2/4">
                  <h1 className="text-3xl font-normal text-center lg:text-5xl text-blanco">
                    {t("Titulo_Localidades")}
                  </h1>
                </div>
              </header>
            </div>
          </section>
        </main>
      </div>

      <Locations />

      <Footer />
    </>
  );
}
