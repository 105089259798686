import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import FavouritesComponent from "./FavouritesComponent";
// import LanguageComponent from "../../Components/Language";
import Whatsapp from "../../Components/Whatsapp";
// import Sidebar from "../../Components/Sidebar";

export default function Favourites() {
  return (
    <>
      <div className="absolute hidden w-full h-1/5 lg:block bg-marino"></div>
      {/* <div className="absolute z-30 flex items-center justify-end w-11/12 h-24 ml-6 bg-marino lg:ml-24 lg:h-1/5 ">
        <LanguageComponent />
      </div> */}
      <div className="flex flex-col w-full ">
        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>
        {/* <div className="w-full h-24 lg:hidden">
          <Sidebar />
        </div>{" "} */}
        <div className="w-screen min-h-screen lg:h-screen lg:max-h-screen lg:mb-24">
          <main className="relative flex flex-col w-full h-auto lg:h-full ">
            <Navbar />
          </main>
        </div>
        <div className="absolute w-full h-auto pt-16 lg:pt-32 ">
          <h1 className="mt-20 text-3xl text-center">Favourites</h1>

          <FavouritesComponent />

          <Footer />
        </div>
      </div>
    </>
  );
}
