import { Carousel } from "flowbite-react";
import { useTranslation } from "react-i18next";

export default function SliderComponent() {
  const {t} = useTranslation();
  return (
    <>
      <div className="h-96 sm:h-96 bg-marino xl:h-80 2xl:h-96">
        <Carousel className=" h-96" leftControl="." rightControl=".">
          <div className="flex flex-col p-4 text-center gap-y-6 text-blanco">
            <h2>1</h2>
            <h2>{t("Busqueda")}</h2>
            <h2>
            {t("Busqueda_Texto")}
            </h2>
          </div>

          <div className="flex flex-col p-4 text-center gap-y-6 text-blanco">
            <h2>2</h2>
            <h2>{t("Seleccion")}</h2>
            <h2>
            {t("Seleccion_Texto")}
            </h2>
          </div>
          <div className="flex flex-col p-4 text-center gap-y-6 text-blanco">
            <h2>3</h2>
            <h2>{t("Negociacion")}</h2>
            <h2>
            {t("Negociacion_Texto")}
            </h2>
          </div>
          <div className="flex flex-col p-4 text-center gap-y-6 text-blanco">
            <h2>4</h2>
            <h2>{t("Cerrar")}</h2>
            <h2>
            {t("Cerrar_Texto")}
            </h2>
          </div>
        </Carousel>
      </div>
    </>
  );
}
