import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Whatsapp from "../../Components/Whatsapp";
import SearchedHouses from "./SearchHouses";

export default function Search() {
  return (
    <>
      <div className="absolute hidden w-full h-1/5 lg:block bg-marino"></div>

      <div className="flex flex-col w-full ">
        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>

        <div className="w-screen min-h-screen lg:h-screen lg:max-h-screen lg:mb-24">
          <main className="relative flex flex-col w-full h-auto lg:h-full ">
            <Navbar />
          </main>
        </div>
        <div className="absolute w-full h-auto pt-16 lg:pt-32 ">
          <SearchedHouses />

          <Footer />
        </div>
      </div>
    </>
  );
}
