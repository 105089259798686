import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import HttpApi from "i18next-http-backend";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/flag-icon-css/css/flag-icons.min.css";
import App from "./App";
import { Suspense } from "react";
import i18n from "i18next";
// import CookieConsent from "react-cookie-consent";
import Cookie from "./Pages/cookies";
import { ImageProvider } from "./Components/Context";
import { ApiProvider } from "./Context/ApiContext";

i18n // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    detection: {
      order: [
        "path",
        "cookie",
        "htmlTag",
        "localStorage",
        "navigator",
        "subdomain",
      ],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const loadingMarkup = (
  <div className="py-4 text-center">
    <h2>Loading....</h2>
  </div>
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Suspense fallback={loadingMarkup}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Cookie />
          <ImageProvider>
            <ApiProvider>
              <App />
            </ApiProvider>
          </ImageProvider>
        </I18nextProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);

reportWebVitals();
