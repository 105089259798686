import Filter from "../../Components/Filter";
import Navbar from "../../Components/Navbar";
// import Sidebar from "../../Components/Sidebar";
import Whatsapp from "../../Components/Whatsapp";
import { useTranslation } from "react-i18next";
import Footer from "../../Components/Footer";
import Locations from "../../Components/Locations";
// import LanguageComponent from "../../Components/Language";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useApi } from "../../Context/ApiContext";

export default function Santa() {
  const { t, i18n } = useTranslation();
  const [houses, setHouses] = useState([]);
  const { lang } = useParams();
  const apiData = useApi(); // Utiliza el hook useApi del contexto

  const translations = [
    { lang: "es", index: "1" },
    { lang: "en", index: "2" },
    { lang: "de", index: "3" },
    { lang: "fr", index: "4" },
    { lang: "nl", index: "5" },
    { lang: "ru", index: "7" },
  ];

  const currentLanguageIndex = translations.findIndex(
    (t) => t.lang === i18n.language
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const lang = Cookies.get("i18next") || "en"; // default to English

    if (apiData) {
      const desiredCity = "Santa Cristina d'Aro";
      const filteredHouses = apiData?.filter((house) =>
        house.ciudad.includes(desiredCity)
      );
      setHouses(filteredHouses);
      Cookies.set("i18next", lang);
    }
  }, [apiData]); // Agrega apiData como dependencia al useEffect

  return (
    <>
      {/* <div className="absolute z-30 flex items-center justify-end w-11/12 h-24 ml-6 bg-marino lg:ml-24 lg:h-1/5 ">
        <LanguageComponent />
      </div> */}
      <div className="w-screen h-screen mb-44 lg:mb-24">
        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>
        {/* <div className="w-full h-24 lg:hidden">
          <Sidebar />
        </div> */}
        <main className="relative flex flex-col w-full h-full bg-marino">
          <Navbar />
          <section className="relative flex w-full h-full ">
            <div className="absolute z-0 w-full h-full bg-center bg-no-repeat bg-cover brightness-75 bg-Houses"></div>
            <div className="z-10 flex flex-col justify-center w-full">
              <header className="flex flex-col items-center justify-evenly h-3/4 ">
                <div className="flex items-center justify-center w-full p-4 lg:p-0 sm:w-1/2 lg:w-2/4 h-2/4">
                  <h1 className="hidden font-normal text-center lg:text-6xl font-minion lg:block text-blanco">
                    {t("Titulo_Comprar")}
                  </h1>
                  <h1 className="text-3xl font-normal text-center lg:hidden font-minion text-blanco">
                    {t("Titulo_Comprar")}
                  </h1>
                </div>
                <Filter />
              </header>
            </div>
          </section>
        </main>
      </div>
      <div className="flex flex-col w-3/4 mx-auto my-0 mb-20 lg:text-center gap-y-8">
        <h2 className="text-xl font-semibold lg:text-4xl">
          Santa Cristina d’Aro
        </h2>
        <h3 className="lg:text-xl ">{t("Santa")}</h3>
      </div>

      <div className="w-full min-h-screen">
        <section className="flex flex-col items-center w-full min-h-screen">
          <div className="flex flex-col w-full h-40 mb-8 justify-evenly">
            <div className="w-full ">
              <h2 className="text-2xl font-bold text-center font-minion lg:text-4xl ">
                {t("Propiedades_Destacadas")}
              </h2>
            </div>
            <div className="w-full p-4 font-sans">
              <h3 className="hidden text-center lg:block text-gris">
                {t("Debajo_Locations_1")} <br />
                {t("Debajo_Locations_2")}
              </h3>

              <h3 className="p-4 text-base text-center lg:hidden text-gris">
                {t("Debajo_Locations")}
              </h3>
            </div>
          </div>

          <div className="flex flex-col w-11/12 h-full font-sans gap-y-14 lg:grid lg:grid-cols-2 lg:grid-rows-3 lg:gap-y-16 lg:justify-items-center">
            {houses.map((item) => {
              return (
                <Link
                  to={{
                    pathname: `/${lang}/house/${item.ref}`,
                    state: { houses: item },
                  }}
                  key={item.id}
                  className="z-0 rounded-lg shadow-xl w-12/12 lg:w-10/12 shadow-gris"
                >
                  <div className="flex justify-end w-full h-auto">
                    <div className="relative w-full h-56 overflow-hidden lg:h-80">
                      <img
                        alt="Ciudad"
                        className="w-full h-full overflow-hidden transition-transform duration-300 ease-in-out rounded-t-lg hover:scale-150 "
                        src={item.foto1}
                      />
                    </div>
                    {item[`descrip${translations[currentLanguageIndex].index}`]
                      .toString()
                      .replace("~", "")
                      .substring(0, 7) === "NINGUNA" ? (
                      <div className="absolute z-30 items-center justify-center hidden w-48 text-white rounded lg:h-14 bg-gris-blanco">
                        No hay descripción disponible
                      </div>
                    ) : (
                      <div className="absolute z-30 flex items-center justify-center h-10 text-xs font-medium text-black rounded w-36 lg:text-md lg:w-40 lg:h-12 bg-lamia">
                        {item[
                          `descrip${translations[currentLanguageIndex].index}`
                        ]
                          .toString()
                          .replace("~", "")
                          .substring(0, 19)}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col justify-center w-11/12 h-auto pt-6 pb-6 mx-auto my-0 ">
                    <div className="flex items-center justify-between w-full lg:h-10 ">
                      <h3 className="text-sm uppercase lg:text-base text-gris">
                        {item.ciudad}
                      </h3>
                      <h3 className="text-sm uppercase lg:text-base text-gris">
                        {item.ref}
                      </h3>
                    </div>
                    <div className="flex items-center justify-between w-full pt-3 lg:h-28 lg:pt-4">
                      <div className="w-3/4">
                        <h2 className="hidden text-base font-semibold text-black lg:block lg:text-xl ">
                          {
                            item[
                              `titulo${translations[currentLanguageIndex].index}`
                            ]
                          }
                        </h2>
                        <h2 className="text-base font-semibold text-black lg:hidden">
                          {
                            item[
                              `titulo${translations[currentLanguageIndex].index}`
                            ]
                          }
                        </h2>
                      </div>
                      <div className="flex justify-end w-1/4">
                        <h2 className="text-base font-semibold text-gris-blanco lg:text-xl">
                          {parseInt(item.precioinmo).toLocaleString("es-ES")}{" "}
                          &#x20AC;
                        </h2>
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full pt-3 lg:h-24 lg:pt-4">
                      <div className="flex flex-col ">
                        <h3 className="text-sm font-semibold text-black lg:text-lg">
                          {item.habdobles}
                        </h3>
                        <h4 className="text-xs font-normal lg:text-base text-gris">
                          {t("Habitaciones")}
                        </h4>
                      </div>

                      <div className="flex flex-col ">
                        <h3 className="text-sm font-semibold text-black lg:text-lg">
                          {item.m_cons} m<sup>2</sup>
                        </h3>
                        <h4 className="text-xs font-normal text-gris lg:text-base">
                          {t("Construida")}
                        </h4>
                      </div>

                      <div className="flex flex-col ">
                        <h3 className="text-sm font-semibold text-black lg:text-lg">
                          {item.m_parcela} m<sup>2</sup>
                        </h3>
                        <h4 className="text-xs font-normal text-gris lg:text-base">
                          {t("Parcela")}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>

          <div className="flex items-center w-full h-40 mt-4 justify-evenly">
            <div className="w-2/12 border-2 lg:w-3/12 border-gris-claro"></div>
            <div className="flex items-center justify-center w-6/12 h-16 rounded-sm lg:w-3/12 bg-gris-blanco">
              <Link
                to="/:lang/Houses/All"
                className="text-xs font-medium lg:font-light lg:text-base text-blanco"
              >
                {t("Ver_Inmuebles")}
              </Link>
            </div>
            <div className="w-2/12 border-2 lg:w-3/12 border-gris-claro"></div>
          </div>
        </section>
      </div>

      <Locations />

      <Footer />
    </>
  );
}
