import { useTranslation } from "react-i18next";
// import LanguageComponent from "../../Components/Language";
import Navbar from "../../Components/Navbar";
// import Sidebar from "../../Components/Sidebar";
import Whatsapp from "../../Components/Whatsapp";
import Footer from "../../Components/Footer";
import { useEffect } from "react";

export default function Terms() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="absolute hidden w-full h-1/5 lg:block bg-marino"></div>
      {/* <div className="absolute z-30 flex items-center justify-end w-11/12 h-24 ml-6 bg-marino lg:ml-24 lg:h-1/5 ">
        <LanguageComponent />
      </div> */}
      <div className="flex flex-col w-full ">
        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>
        {/* <div className="w-full h-24 lg:hidden">
          <Sidebar />
        </div>{" "} */}
        <div className="w-screen min-h-screen lg:h-screen lg:max-h-screen lg:mb-24">
          <main className="relative flex flex-col w-full h-auto lg:h-full ">
            <Navbar />
          </main>
        </div>
        <div className="absolute flex flex-col w-full h-auto pt-24 gap-y-8 lg:pt-48 ">
          <div className="flex flex-col p-4 text-justify lg:mx-auto lg:my-0 lg:w-2/3 gap-y-8 ">
            <div className="pt-10 text-2xl text-center">
              <h1>{t("Politicas")}</h1>
            </div>

            <div className="">
              <h2 className="">{t("Politica-Intro")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Informacion-Title")}</h2>
              <h2>{t("Informacion-Desc")}</h2>
              <ul className="flex flex-col gap-y-4">
                <li>{t("Informacion-Nombre")}</li>
                <li>{t("Informacion-Financiera")}</li>
                <li>{t("Informacion-Propiedades")}</li>
                <li>{t("Informacion-Personal")}</li>
              </ul>
              <h2>{t("Informacion-Desc-1")}</h2>
              <ul className="flex flex-col gap-y-4">
                <li>{t("Informacion-Servicios")}</li>
                <li>{t("Informacion-Comunicacion")}</li>
                <li>{t("Informacion-Personalizacion")}</li>
                <li>{t("Informacion-Cumplir")}</li>
              </ul>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Datos-Adicionales")}</h2>
              <h2>{t("Datos-Adicionales-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Consentimiento")}</h2>
              <h2>{t("Consentimiento-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Retencion")}</h2>
              <h2>{t("Retencion-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Transferencia")}</h2>
              <h2>{t("Transferencia-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Proteccion")}</h2>
              <h2>{t("Proteccion-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Cookies")}</h2>
              <h2>{t("Cookies-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Sensibles")}</h2>
              <h2>{t("Sensibles-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Compartir")}</h2>
              <h2>{t("Compartir-Texto")}</h2>
              <ul className="flex flex-col gap-y-4">
                <li>{t("Explicito")}</li>
                <li>{t("Proveedores")}</li>
                <li>{t("Divulgacion")}</li>
              </ul>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Derechos")}</h2>
              <h2>{t("Derechos-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Aceptacion")}</h2>
              <h2>{t("Aceptacion-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Intelectual")}</h2>
              <h2>{t("Intelectual-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Responsabilidad")}</h2>
              <h2>{t("Responsabilidad-Texto")}</h2>

              <ul className="flex flex-col gap-y-4">
                <li>{t("Proporcionar")}</li>
                <li>{t("Mantener")}</li>
                <li>{t("Utilizar")}</li>
                <li>{t("Noutilizar")}</li>
                <li>{t("Respetar")}</li>
              </ul>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Limitacion")}</h2>
              <h2>{t("Limitacion-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Cambios")}</h2>
              <h2>{t("Cambios-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Informacion-Contacto")}</h2>
              <h2>{t("Informacion-Contacto-Texto")}</h2>
              <h2>{t("In-Nombre")}</h2>
              <h2>{t("In-Email")}</h2>
              <h2>{t("In-Lamia")}</h2>
              <h2>{t("In-Nif")}</h2>
              <h2>{t("In-Aicat")}</h2>
              <h2>{t("In-Direccion")}</h2>
              <h2>{t("In-Telefono")}</h2>
              <h2>{t("Informacion-Contacto-Texto-1")}</h2>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
