import Filter from "../../Components/Filter";
import Navbar from "../../Components/Navbar";
// import Sidebar from "../../Components/Sidebar";
import Whatsapp from "../../Components/Whatsapp";
import { useTranslation } from "react-i18next";
// import HousesComponent from "../../Components/Houses";
import Footer from "../../Components/Footer";
import Locations from "../../Components/Locations";
import { useEffect } from "react";
// import LanguageComponent from "../../Components/Language";
import LocationHouses from "../../Components/LocationHouses";

export default function Blanes({ cityText }) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* {" "}
      <div className="absolute z-30 flex items-center justify-end w-11/12 h-24 ml-6 bg-marino lg:ml-24 lg:h-1/5 ">
        <LanguageComponent />
      </div> */}
      <div className="w-screen h-screen mb-44 lg:mb-24">
        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>
        {/* <div className="w-full h-24 lg:hidden">
          <Sidebar />
        </div> */}
        <main className="relative flex flex-col w-full h-full bg-marino">
          <Navbar />
          <section className="relative flex w-full h-full ">
            <div className="absolute z-0 w-full h-full bg-center bg-no-repeat bg-cover brightness-75 bg-Houses"></div>
            <div className="z-10 flex flex-col justify-center w-full">
              <header className="flex flex-col items-center justify-evenly h-3/4 ">
                <div className="flex items-center justify-center w-full p-4 lg:p-0 sm:w-1/2 lg:w-2/4 h-2/4">
                  <h1 className="hidden font-normal text-center lg:text-6xl font-minion lg:block text-blanco">
                    {t("Titulo_Comprar")}
                  </h1>
                  <h1 className="text-3xl font-normal text-center lg:hidden font-minion text-blanco">
                    {t("Titulo_Comprar")}
                  </h1>
                </div>
                <Filter />
              </header>
            </div>
          </section>
        </main>
      </div>
      <div className="flex flex-col w-3/4 mx-auto my-0 mb-20 lg:text-center gap-y-8">
        <h2 className="text-xl font-semibold lg:text-4xl">Blanes</h2>
        <h3 className="lg:text-xl ">
          {t("Blanes")}
          <br />
          <br />
          {t("Blanes_1")}
          <br />
          <br />
          {t("Blanes_2")}
          <br />
          <br />
          {t("Blanes_3")}
          <br />
          <br />
          {t("Blanes_4")}
        </h3>
      </div>
      <LocationHouses cityText={cityText} />
      <Locations />
      <Footer />
    </>
  );
}
