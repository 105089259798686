import logo from "../assets/logo/logonobg.png";
import Punto from "../assets/icons/Ellipse 5.svg";
import Facebook from "../assets/icons/facebook.png";
import Instagram from "../assets/icons/instagram.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full h-auto mt-10 font-sans bg-marino">
        <div className="flex flex-col w-10/12 h-auto pt-8 mx-auto my-0">
          <div className="flex flex-col w-full h-auto lg:flex-row">
            <div className="flex flex-col w-full lg:gap-y-4 h-96 lg:h-64">
              <div className="relative flex items-center w-full h-2/6">
                <img
                  src={logo}
                  className="absolute -ml-8 w-28 lg:w-40 "
                  alt="nobg"
                />
              </div>
              {/* <div className="flex items-center w-full h-1/6">
                <h2 className="text-base font-bold text-blanco">2023</h2>
              </div> */}
              <div className="flex flex-col w-full text-sm font-normal h-2/6 justify-evenly text-blanco">
                <h2>© Kaylam</h2>
                <a href="tel:+34972608553">(+34) 972 60 85 53</a>
                <a href="mailto:info@kaylamrealty.com">
                  info@kaylamrealty.com
                </a>{" "}
              </div>
            </div>
            <div className="flex items-center w-full h-64 ">
              <div className="flex flex-col w-1/2 h-full lg:h-4/6 ">
                <div className="flex items-center w-full mb-4 h-1/6">
                  <h2 className="text-base font-semibold lg:text-lg text-blanco">
                    {t("Enlaces")}
                  </h2>
                </div>
                <div className="flex flex-col w-full gap-y-4">
                  <div className="flex w-full item-center">
                    <img src={Punto} alt="punto" className="w-2 mr-2" />
                    <Link
                      to="/:lang/Buy"
                      className="text-sm font-normal text-blanco"
                    >
                      {t("Comprar")}
                    </Link>
                  </div>
                  <div className="flex w-full item-center">
                    <img src={Punto} alt="punto" className="w-2 mr-2" />
                    <Link
                      to="/:lang/Sell"
                      className="text-sm font-normal text-blanco"
                    >
                      {t("Vender")}
                    </Link>
                  </div>
                  <div className="flex w-full item-center">
                    <img src={Punto} alt="punto" className="w-2 mr-2" />
                    <Link
                      to="/:lang/Rent"
                      className="text-sm font-normal text-blanco"
                    >
                      {t("Alquilar")}
                    </Link>
                  </div>
                  <div className="flex w-full item-center">
                    <img src={Punto} alt="punto" className="w-2 mr-2" />
                    <Link
                      to="/:lang/About"
                      className="text-sm font-normal text-blanco"
                    >
                      {t("Sobre_Nosotros")}
                    </Link>
                  </div>
                  <div className="flex w-full item-center">
                    <img
                      src={Punto}
                      alt="punto"
                      className="w-2 mr-2 lg:gap-y-0"
                    />
                    <Link
                      to="/:lang/Contact"
                      className="text-sm font-normal text-blanco"
                    >
                      {t("Contacto")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2 h-full lg:h-4/6">
                <div className="flex items-center w-full mb-4 h-1/6">
                  <h2 className="text-base font-semibold lg:text-lg text-blanco">
                    {t("Legal")}
                  </h2>
                </div>
                <div className="flex flex-col w-full gap-y-4">
                  <div className="flex w-full item-center">
                    <img src={Punto} alt="punto" className="w-2 mr-2" />
                    <Link
                      to="/:lang/Terms"
                      className="text-sm font-normal text-blanco"
                    >
                      {t("Politica")}
                    </Link>
                  </div>
                  <div className="flex w-full item-center">
                    <img src={Punto} alt="punto" className="w-2 mr-2" />
                    <Link
                      to="/:lang/Legal"
                      className="text-sm font-normal text-blanco"
                    >
                      {t("Terminos")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center w-full h-64">
              <div className="flex flex-col w-1/2 h-full lg:h-4/6">
                <div className="flex items-center w-full mb-4 h-1/6">
                  <h2 className="text-base font-semibold lg:text-lg text-blanco">
                    {t("Redes")}
                  </h2>
                </div>
                <div className="flex flex-col w-full gap-y-4">
                  <div className="flex w-full item-center">
                    <img src={Punto} alt="punto" className="w-2 mr-2" />
                    <a
                      href="https://www.facebook.com/profile.php?id=100090178729164"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h3 className="text-sm font-normal text-blanco">
                        {t("Facebook")}
                      </h3>
                    </a>
                  </div>

                  <div className="flex w-full item-center">
                    <img src={Punto} alt="punto" className="w-2 mr-2" />
                    <a
                      href=" https://instagram.com/kaylam.realty?igshid=YmMyMTA2M2Y="
                      target="_blank"
                    >
                      <h3 className="text-sm font-normal text-blanco">
                        {t("Instagram")}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2 h-full lg:h-4/6">
                <div className="flex items-center w-full mb-4 h-1/6">
                  <h2 className="text-base font-semibold lg:text-lg text-blanco">
                    {t("Zona")}
                  </h2>
                </div>
                <div className="flex flex-col w-full gap-y-4">
                  <div className="flex w-full item-center">
                    <img src={Punto} alt="punto" className="w-2 mr-2" />
                    <a
                      className="text-sm font-normal text-blanco"
                      href="https://goo.gl/maps/tqVVNujNgqz9SMJQA"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Costa")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full h-20 gap-y-8">
            <div className="w-full h-0.5 bg-gris-blanco"></div>
            <div className="flex justify-between w-full ">
              <div className="flex items-center justify-between w-10/12">
                <h3 className="text-xs text-blanco ">
                  Copyright 2023 © Kaylam
                </h3>
                {/* <a
                  href="https://lagenceducygne.com/"
                  className="text-xs text-blanco"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Donia")}
                </a> */}
              </div>
              <div className="flex gap-x-3">
                <a
                  href="https://www.facebook.com/profile.php?id=100090178729164"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} className="" alt="facebook" />
                </a>
                <a
                  href=" https://instagram.com/kaylam.realty?igshid=YmMyMTA2M2Y="
                  target="_blank"
                >
                  <img src={Instagram} className="" alt="facebook" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
