import { useTranslation } from "react-i18next";
// import LanguageComponent from "../../Components/Language";
import Navbar from "../../Components/Navbar";
// import Sidebar from "../../Components/Sidebar";
import Whatsapp from "../../Components/Whatsapp";
import Footer from "../../Components/Footer";
import { useEffect } from "react";

export default function Legal() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="absolute hidden w-full h-1/5 lg:block bg-marino"></div>
      {/* <div className="absolute z-30 flex items-center justify-end w-11/12 h-24 ml-6 bg-marino lg:ml-24 lg:h-1/5 ">
        <LanguageComponent />
      </div> */}
      <div className="flex flex-col w-full ">
        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>
        {/* <div className="w-full h-24 lg:hidden">
          <Sidebar />
        </div>{" "} */}
        <div className="w-screen min-h-screen lg:h-screen lg:max-h-screen lg:mb-24">
          <main className="relative flex flex-col w-full h-auto lg:h-full ">
            <Navbar />
          </main>
        </div>
        <div className="absolute flex flex-col w-full h-auto pt-24 gap-y-8 lg:pt-48 ">
          <div className="flex flex-col p-4 text-justify lg:mx-auto lg:my-0 lg:w-2/3 gap-y-8 ">
            <div className="pt-10 text-2xl text-center">
              <h1>{t("Legal")}</h1>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Duracion")}</h2>
              <h2>{t("Duracion-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Terceros")}</h2>
              <h2>{t("Terceros-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Descargo")}</h2>
              <h2>{t("Descargo-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Aplicables")}</h2>
              <h2>{t("Aplicables-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2 className="font-bold ">{t("Divisibilidad")}</h2>
              <h2>{t("Divisibilidad-Texto")}</h2>
            </div>

            <div className="flex flex-col gap-y-4">
              <h2>{t("Final-Texto")}</h2>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
