import WhatsApp from "../assets/icons/WhatsApp.png";
export default function Whatsapp() {
  return (
    <>
      <div className="fixed z-30 flex items-end justify-end ">
        <a href="https://wa.me/message/XXPL3HKZEFTWJ1" rel="noreferrer" target="_blank">
          <img src={WhatsApp} className="w-16 lg:w-24" alt="" />
        </a>
      </div>
    </>
  );
}
