import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApi } from "../../Context/ApiContext";

function AllHousesComponent() {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const [filter, setFilter] = useState("");
  const houses = useApi();
  const [originalHouses, setOriginalHouses] = useState([]);
  const [filteredHouses, setFilteredHouses] = useState([]);

  useEffect(() => {
    if (houses) {
      setOriginalHouses(houses);
      setFilteredHouses(houses);
    }
  }, [houses]);

  const translations = [
    { lang: "es", index: "1" },
    { lang: "en", index: "2" },
    { lang: "de", index: "3" },
    { lang: "fr", index: "4" },
    { lang: "nl", index: "5" },
    { lang: "ru", index: "7" },
  ];

  const currentLanguageIndex = translations.findIndex(
    (t) => t.lang === i18n.language
  );

  // setHouses(result.propiedades.propiedad);
  // Cookies.set("i18next", lang);

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setFilter(selectedFilter);

    // Aplica la lógica de filtrado según la opción seleccionada
    let updatedHouses = [...originalHouses];
    let filteredHouses = [];

    switch (selectedFilter) {
      case "relevancia":
        filteredHouses = updatedHouses.filter(
          (house) => house["exclusiva"][0] === "1"
        );
        break;

      case "precio-alto":
        filteredHouses = updatedHouses.sort(
          (a, b) => b["precioinmo"] - a["precioinmo"]
        );
        break;
      case "precio-bajo":
        filteredHouses = updatedHouses.sort(
          (a, b) => a["precioinmo"] - b["precioinmo"]
        );
        break;
      case "mas-reciente":
        filteredHouses = updatedHouses.sort(
          (a, b) => new Date(b.fecha) - new Date(a.fecha)
        );
        break;
      case "mas-antiguo":
        filteredHouses = updatedHouses.sort(
          (a, b) => new Date(a.fecha) - new Date(b.fecha)
        );
        break;
      default:
        // No se seleccionó ningún filtro, mantener todas las casas sin cambios
        filteredHouses = updatedHouses;
        break;
    }

    // Actualiza el estado de las casas filtradas
    setFilteredHouses(filteredHouses);
  };

  const [recents, setRecents] = useState([]);

  useEffect(() => {
    const savedRecents = Cookies.get("recientes");
    if (savedRecents) {
      setRecents(JSON.parse(savedRecents));
    }
  }, []);

  function handleRecent(ref) {
    window.scrollTo(0, 0); // Mueve la página hacia arriba

    const stringRef = ref.toString();
    const updatedRecents = [...recents];

    if (updatedRecents.includes(stringRef)) {
      const index = updatedRecents.indexOf(stringRef);
      updatedRecents.splice(index, 1);
    } else {
      updatedRecents.push(stringRef);
    }

    setRecents(updatedRecents);
    Cookies.set("recientes", JSON.stringify(updatedRecents));
  }

  return (
    <>
      <div className="w-full min-h-screen mt-16">
        <section className="flex flex-col items-center w-full min-h-screen">
          <select
            value={filter}
            className="mb-16 rounded"
            onChange={handleFilterChange}
          >
            <option value="">{t("Ordenar_Por")}</option>
            <option value="relevancia">{t("Relevancia")}</option>
            <option value="precio-alto">{t("Precio_Alto")}</option>
            <option value="precio-bajo">{t("Precio_Bajo")}</option>
            <option value="mas-reciente">{t("Mas_Reciente")}</option>
            <option value="mas-antiguo">{t("Mas_Antiguo")}</option>
          </select>

          <div className="flex flex-col w-11/12 h-full font-sans gap-y-14 lg:grid lg:grid-cols-2 lg:grid-rows-3 lg:gap-y-16 lg:justify-items-center">
            {filteredHouses.map((item) => {
              return (
                <Link
                  to={{
                    pathname: `/${lang}/house/${item.ref}`,
                    state: { houses: item },
                  }}
                  key={item.id}
                  onClick={() => handleRecent(item.ref)} // Llamada a handleRecent con el ref como argumento
                  className="z-0 rounded-lg shadow-xl w-12/12 lg:w-10/12 shadow-gris"
                >
                  <div className="flex justify-end w-full h-auto">
                    <div className="relative w-full h-56 overflow-hidden lg:h-80">
                      <img
                        alt="Ciudad"
                        className="w-full h-full overflow-hidden transition-transform duration-300 ease-in-out rounded-t-lg hover:scale-150 "
                        src={item.foto1}
                      />
                    </div>
                    {item[`descrip${translations[currentLanguageIndex].index}`]
                      .toString()
                      .replace("~", "")
                      .substring(0, 7) === "NINGUNA" ? (
                      <div className="absolute z-30 items-center justify-center hidden w-48 text-white rounded lg:h-14 bg-gris-blanco">
                        No hay descripción disponible
                      </div>
                    ) : (
                      <div className="absolute z-30 flex items-center justify-center h-10 text-xs font-medium text-black rounded w-36 lg:text-md lg:w-40 lg:h-12 bg-lamia">
                        {item[
                          `descrip${translations[currentLanguageIndex].index}`
                        ]
                          .toString()
                          .replace("~", "")
                          .substring(0, 19)}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-center w-11/12 h-auto pt-6 pb-6 mx-auto my-0 gap-y-4 ">
                    <div className="flex items-center justify-between w-full lg:h-10 ">
                      <h3 className="text-sm uppercase lg:text-base text-gris">
                        {item.ciudad}
                      </h3>
                      <h3 className="text-sm uppercase lg:text-base text-gris">
                        {item.ref}
                      </h3>
                    </div>
                    <div className="flex items-center justify-between w-full lg:h-auto ">
                      <div className="w-full">
                        <h2 className="hidden text-base font-semibold text-black lg:block lg:text-xl ">
                          {
                            item[
                              `titulo${translations[currentLanguageIndex].index}`
                            ]
                          }
                        </h2>
                        <h2 className="text-base font-semibold text-black lg:hidden">
                          {
                            item[
                              `titulo${translations[currentLanguageIndex].index}`
                            ]
                          }
                        </h2>
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full lg:h-20 ">
                      <div className="flex flex-col ">
                        <h3 className="text-sm font-semibold text-black lg:text-lg">
                          {item.habdobles}
                        </h3>
                        <h4 className="text-xs font-normal lg:text-base text-gris">
                          {t("Habitaciones")}
                        </h4>
                      </div>

                      <div className="flex flex-col ">
                        <h3 className="text-sm font-semibold text-black lg:text-lg">
                          {parseFloat(item.m_cons).toFixed(0)} m<sup>2</sup>{" "}
                        </h3>

                        <h4 className="text-xs font-normal text-gris lg:text-base">
                          {t("Construida")}
                        </h4>
                      </div>

                      <div className="flex flex-col ">
                        <h3 className="text-sm font-semibold text-black lg:text-lg">
                          {item.m_parcela} m<sup>2</sup>
                        </h3>
                        <h4 className="text-xs font-normal text-gris lg:text-base">
                          {t("Parcela")}
                        </h4>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <h2 className="text-lg font-bold text-gris-blanco lg:text-2xl">
                        {parseInt(item.precioinmo).toLocaleString("es-ES")}{" "}
                        &#x20AC;
                      </h2>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
}

export default AllHousesComponent;
