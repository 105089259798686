import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useApi } from "../Context/ApiContext";

export default function Recent({ localidad, Price }) {
  const { t, i18n } = useTranslation();
  // const [houses, setHouses] = useState([]);
  const [casas, setCasas] = useState([]);
  // const [recents, setRecents] = useState([]);
  const { lang } = useParams();

  const apiData = useApi();

  const translations = [
    { lang: "es", index: "1" },
    { lang: "en", index: "2" },
    { lang: "de", index: "3" },
    { lang: "fr", index: "4" },
    { lang: "nl", index: "5" },
    { lang: "ru", index: "7" },
  ];

  const currentLanguageIndex = translations.findIndex(
    (t) => t.lang === i18n.language
  );

  // function shuffle(array) {
  //   let currentIndex = array.length,
  //     temporaryValue,
  //     randomIndex;

  //   while (currentIndex !== 0) {
  //     randomIndex = Math.floor(Math.random() * currentIndex);
  //     currentIndex -= 1;

  //     temporaryValue = array[currentIndex];
  //     array[currentIndex] = array[randomIndex];
  //     array[randomIndex] = temporaryValue;
  //   }

  //   return array;
  // }

  // Get saved recents from cookies on component mount
  useEffect(() => {
    const savedRecents = Cookies.get("recientes");
    if (savedRecents) {
      // const recentRefs = JSON.parse(savedRecents);
      // setRecents(recentRefs);
    }
  }, []);

  useEffect(() => {
    if (localidad && apiData) {
      const fetchedHouses = apiData;

      // Filtrar por ciudad y precio con diferencia de 200,000 o más, o diferencia de -200,000 o menos
      const filteredHouses = fetchedHouses.filter((house) => {
        const ciudadMatch =
          house.ciudad.toLowerCase() === String(localidad).toLowerCase();
        const housePrice = parseInt(house.precioinmo); // Assuming the 'precioinmo' property is a string representing a number
        const priceDifference = Math.abs(housePrice - Price);

        return ciudadMatch && priceDifference <= 200000;
      });

      // Ordenar aleatoriamente la lista de casas
      const shuffledHouses = filteredHouses.sort(() => 0.5 - Math.random());

      // Mostrar solo las primeras 6 casas
      const selectedHouses = shuffledHouses.slice(0, 6);
      setCasas(selectedHouses);

      Cookies.set("i18next", lang);
    }
  }, [localidad, Price, apiData, lang]);

  // Filter houses based on saved recents
  // const filteredHouses = houses.filter((house) => {
  //   const isRecent = recents.includes(house.ref);
  //   return isRecent;
  // });

  // Handle favorite toggle

  // Filter houses based on saved favorites

  const handleClick = () => {
    window.scrollTo(0, 0); // Mueve la página hacia arriba
  };

  // function handleLinkClick(ref) {

  //   window.scrollTo(0, 0);

  //   const stringRef = ref.toString();
  //   const updatedRecents = [...recents];

  //   if (updatedRecents.includes(stringRef)) {
  //     const index = updatedRecents.indexOf(stringRef);
  //     updatedRecents.splice(index, 1);
  //   }

  //   updatedRecents.unshift(stringRef);
  //   updatedRecents.slice(0, 10); // Limitar la cantidad de elementos guardados en 'recientes'

  //   setRecents(updatedRecents);
  //   Cookies.set("recientes", JSON.stringify(updatedRecents));
  // }

  // Resto del código...

  // Resto del código...

  return (
    <>
      {localidad ? (
        <div className="w-full h-auto ">
          <section className="flex flex-col items-center w-full h-auto ">
            {/* <div className="flex flex-col w-full h-40 mb-8 justify-evenly">
            <div className="w-full ">
              <h2 className="text-2xl font-bold text-center font-minion lg:text-4xl ">
                {t("Propiedades_Destacadas")}
              </h2>
            </div>
            <div className="w-full p-4 font-sans">
              <h3 className="hidden text-center lg:block text-gris">
                {t("Debajo_Locations_1")} <br />
                {t("Debajo_Locations_2")}
              </h3>

              <h3 className="p-4 text-base text-center lg:hidden text-gris">
                {t("Debajo_Locations")}
              </h3>
            </div>
          </div>

          <select
            value={filter}
            className="mb-16 rounded"
            onChange={handleFilterChange}
          >
            <option value="">{t("Ordenar_Por")}</option>
            <option value="relevancia">{t("Relevancia")}</option>
            <option value="precio-alto">{t("Precio_Alto")}</option>
            <option value="precio-bajo">{t("Precio_Bajo")}</option>
            <option value="mas-reciente">{t("Mas_Reciente")}</option>
            <option value="mas-antiguo">{t("Mas_Antiguo")}</option>
          </select> */}

            <div className="w-full h-24 text-2xl font-bold text-center lg:text-4xl font-minion">
              <h2>Propiedades similares</h2>
            </div>

            <div
              className={`flex flex-col w-full font-sans  gap-y-14 lg:grid ${
                casas.length > 3
                  ? "lg:grid-cols-3 lg:grid-rows-2"
                  : "lg:grid-cols-3 lg:grid-rows-1"
              } lg:gap-y-16 lg:justify-items-center`}
            >
              {casas.map((item) => {
                return (
                  <Link
                    to={{
                      pathname: `/${lang}/house/${item.ref}`,
                      state: { houses: item },
                    }}
                    onClick={handleClick}
                    key={item.id}
                    className="z-0 h-auto rounded-lg shadow-xl w-12/12 lg:w-11/12 shadow-gris"
                  >
                    <div className="flex justify-end w-full h-auto ">
                      <div className="relative w-full h-56 overflow-hidden lg:h-64 ">
                        <img
                          alt="Ciudad"
                          className="w-full h-full overflow-hidden transition-transform duration-300 ease-in-out rounded-t-lg hover:scale-150 "
                          src={item.foto1}
                        />
                      </div>
                      {item[
                        `descrip${translations[currentLanguageIndex].index}`
                      ]
                        .toString()
                        .replace("~", "")
                        .substring(0, 7) === "NINGUNA" ? (
                        <div className="absolute z-30 items-center justify-center hidden w-48 text-white rounded lg:h-14 bg-gris-blanco">
                          No hay descripción disponible
                        </div>
                      ) : (
                        <div className="absolute z-30 flex items-center justify-center h-10 text-xs font-medium text-black rounded w-36 lg:text-md lg:w-40 lg:h-12 bg-lamia">
                          {item[
                            `descrip${translations[currentLanguageIndex].index}`
                          ]
                            .toString()
                            .replace("~", "")
                            .substring(0, 19)}
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col justify-between w-11/12 h-auto pt-4 pb-4 mx-auto my-0 gap-y-4">
                      <div className="flex items-center justify-between w-full ">
                        <h3 className="text-sm uppercase lg:text-base text-gris">
                          {item.ciudad}
                        </h3>
                        <h3 className="text-sm uppercase lg:text-base text-gris">
                          {item.ref}
                        </h3>
                      </div>
                      <div className="flex items-center justify-between w-full h-auto">
                        <div className="w-full">
                          <h2 className="hidden text-base font-semibold text-black lg:block lg:text-lg ">
                            {
                              item[
                                `titulo${translations[currentLanguageIndex].index}`
                              ]
                            }
                          </h2>
                          <h2 className="text-base font-semibold text-black lg:hidden">
                            {
                              item[
                                `titulo${translations[currentLanguageIndex].index}`
                              ]
                            }
                          </h2>
                        </div>
                      </div>
                      <div className="flex items-center justify-between w-full ">
                        <div className="flex flex-col ">
                          <h3 className="text-sm font-semibold text-black lg:text-lg">
                            {item.habdobles}
                          </h3>
                          <h4 className="text-xs font-normal lg:text-base text-gris">
                            {t("Habitaciones")}
                          </h4>
                        </div>

                        <div className="flex flex-col ">
                          <h3 className="text-sm font-semibold text-black lg:text-lg">
                            {parseFloat(item.m_cons).toFixed(0)} m<sup>2</sup>{" "}
                          </h3>

                          <h4 className="text-xs font-normal text-gris lg:text-base">
                            {t("Construida")}
                          </h4>
                        </div>

                        <div className="flex flex-col ">
                          <h3 className="text-sm font-semibold text-black lg:text-lg">
                            {item.m_parcela} m<sup>2</sup>
                          </h3>
                          <h4 className="text-xs font-normal text-gris lg:text-base">
                            {t("Parcela")}
                          </h4>
                        </div>
                      </div>
                      <div className="flex w-full">
                        <h2 className="text-lg font-bold text-gris-blanco lg:text-2xl">
                          {parseInt(item.precioinmo).toLocaleString("es-ES")}{" "}
                          &#x20AC;
                        </h2>
                      </div>
                    </div>
                  </Link>
                );
              })}
              {/* <div className="flex items-center justify-center text-4xl font-bold font-minion lg:col-span-2">
              <h2>Vistas recientemente</h2>
            </div>

            {filteredHouses.map((item) => {
              return (
                <Link
                  to={{
                    pathname: `/${lang}/house/${item.ref}`,
                    state: { houses: item },
                  }}
                  onClick={handleClick}
                  key={item.id}
                  className="z-0 h-auto rounded-lg shadow-xl w-12/12 lg:w-11/12 shadow-gris"
                >
                  <div className="flex justify-end w-full h-auto ">
                    <div className="relative w-full h-56 overflow-hidden lg:h-64 ">
                      <img
                        alt="Ciudad"
                        className="w-full h-full overflow-hidden transition-transform duration-300 ease-in-out rounded-t-lg hover:scale-150 "
                        src={item.foto1}
                      />
                    </div>
                    {item[`descrip${translations[currentLanguageIndex].index}`]
                      .toString()
                      .replace("~", "")
                      .substring(0, 7) === "NINGUNA" ? (
                      <div className="absolute z-30 items-center justify-center hidden w-48 text-white rounded lg:h-14 bg-gris-blanco">
                        No hay descripción disponible
                      </div>
                    ) : (
                      <div className="absolute z-30 flex items-center justify-center h-10 text-xs font-medium text-white rounded w-36 lg:text-md lg:w-40 lg:h-12 bg-donia">
                        {item[
                          `descrip${translations[currentLanguageIndex].index}`
                        ]
                          .toString()
                          .replace("~", "")
                          .substring(0, 19)}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col justify-between w-11/12 h-auto pt-4 pb-4 mx-auto my-0 gap-y-4">
                    <div className="flex items-center justify-between w-full ">
                      <h3 className="text-sm uppercase lg:text-base text-gris">
                        {item.ciudad}
                      </h3>
                      <h3 className="text-sm uppercase lg:text-base text-gris">
                        {item.ref}
                      </h3>
                    </div>
                    <div className="flex items-center justify-between w-full h-auto">
                      <div className="w-full">
                        <h2 className="hidden text-base font-semibold text-black lg:block lg:text-xl ">
                          {
                            item[
                              `titulo${translations[currentLanguageIndex].index}`
                            ]
                          }
                        </h2>
                        <h2 className="text-base font-semibold text-black lg:hidden">
                          {
                            item[
                              `titulo${translations[currentLanguageIndex].index}`
                            ]
                          }
                        </h2>
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full ">
                      <div className="flex flex-col ">
                        <h3 className="text-sm font-semibold text-black lg:text-lg">
                          {item.habdobles}
                        </h3>
                        <h4 className="text-xs font-normal lg:text-base text-gris">
                          {t("Habitaciones")}
                        </h4>
                      </div>

                      <div className="flex flex-col ">
                        <h3 className="text-sm font-semibold text-black lg:text-lg">
                          {parseFloat(item.m_cons).toFixed(0)} m<sup>2</sup>{" "}
                        </h3>

                        <h4 className="text-xs font-normal text-gris lg:text-base">
                          {t("Construida")}
                        </h4>
                      </div>

                      <div className="flex flex-col ">
                        <h3 className="text-sm font-semibold text-black lg:text-lg">
                          {item.m_parcela} m<sup>2</sup>
                        </h3>
                        <h4 className="text-xs font-normal text-gris lg:text-base">
                          {t("Parcela")}
                        </h4>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <h2 className="text-lg font-bold text-gris-blanco lg:text-2xl">
                        {parseInt(item.precioinmo).toLocaleString("es-ES")}{" "}
                        &#x20AC;
                      </h2>
                    </div>
                  </div>
                </Link>
              );
            })} */}
            </div>
          </section>
        </div>
      ) : (
        <div className="w-full h-auto pt-12"></div>
      )}
    </>
  );
}
