import { Link } from "react-router-dom";
import about from "../../assets/icons/about-pic.png";
import Aprobado from "../../assets/icons/aprobado.png";
import Bombilla from "../../assets/icons/bombilla.png";
import Maleta from "../../assets/icons/maleta.png";
import ContactForm from "../../Components/Contact";
import Footer from "../../Components/Footer";
// import LanguageComponent from "../../Components/Language";
import Navbar from "../../Components/Navbar";
// import Sidebar from "../../Components/Sidebar";
import Whatsapp from "../../Components/Whatsapp";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function About() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <div className="absolute z-30 flex items-center justify-end w-11/12 h-24 ml-6 bg-marino lg:ml-24 lg:h-1/5 ">
        <LanguageComponent />
      </div> */}
      <div className="relative w-screen h-screen mb-24 bg-marino lg:mb-0 ">
        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>
        {/* <div className="w-full h-24 lg:hidden">
          <Sidebar />
        </div>{" "} */}
        <main className="relative flex flex-col w-full h-full">
          <Navbar />
          <section className="relative flex w-full h-full ">
            <div className="absolute z-0 w-full h-full bg-center bg-no-repeat bg-cover brightness-75 bg-About"></div>
            <div className="z-10 flex flex-col justify-center w-full h-full lg:w-2/3 lg:mx-auto lg:my-0">
              <div className="flex flex-col w-full h-auto p-2 gap-y-8 ">
                <div className="w-full text-2xl font-normal text-center font-minion lg:text-6xl text-blanco">
                  <h1>{t("Sobre_Nosotros")}</h1>
                </div>
                <div className="w-full font-sans text-base font-light text-center lg:text-xl text-blanco">
                  <h2>{t("Titulo_About")}</h2>

                  <h2>{t("Titulo_About_1")}</h2>
                </div>
                <div className="w-full font-sans text-center">
                  <Link
                    to="/Contact"
                    className="flex items-center justify-center w-2/3 mx-auto my-0 text-center rounded lg:w-1/3 h-14 bg-gris-blanco text-blanco "
                  >
                    {t("Contactenos")}
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>

      <div className="flex items-center w-full min-h-screen">
        <div className="flex flex-col items-center w-full h-auto p-8 lg:flex-row gap-y-6">
          <div className="w-full h-auto p-2 lg:w-2/4 lg:mr-10">
            <img alt="Ciudad" className="w-full" src={about} />
          </div>
          <div className="flex flex-col w-full h-auto gap-y-6">
            <div className="w-full h-auto text-xl font-bold text-center font-minion lg:text-5xl lg:text-left">
              <h2>{t("Texto_About_2")}</h2>
            </div>
            <div className="w-full h-auto font-sans text-base font-normal text-justify lg:font-light lg:text-lg ">
              <h3>
                {t("Texto_About")}
                <br />
                {t("Texto_About_1")}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <section className="w-full min-h-screen pt-10 text-justify">
        <div className="w-full h-auto p-4 ">
          <div className="mb-8 text-xl font-bold text-center font-minion lg:text-4xl">
            <h2> {t("Porque_Kaylam")}</h2>
          </div>
          <div className="flex flex-col w-full h-auto font-sans justify-items-center gap-y-6 lg:grid lg:gap-y-10 lg:grid-cols-3">
            <div className="flex flex-col items-center w-full p-6 shadow-md lg:w-11/12 rounded-xl gap-y-10 shadow-gris">
              <div className="h-auto ">
                <img src={Aprobado} alt="skills" />
              </div>
              <div className="h-auto text-lg font-bold uppercase lg:text-xl">
                <h2>{t("Calidad")}</h2>
              </div>
              <div className="h-auto text-sm font-light lg:text-base text-gris-about ">
                <h3>{t("Calidad_Texto")}</h3>
              </div>
            </div>

            <div className="flex flex-col items-center w-full p-6 shadow-md lg:w-11/12 rounded-xl gap-y-10 shadow-gris">
              <div className="h-auto ">
                <img src={Maleta} alt="skills" />
              </div>
              <div className="h-auto text-lg font-bold uppercase lg:text-xl">
                <h2> {t("Profesionalismo")}</h2>
              </div>
              <div className="h-auto text-sm font-light lg:text-base text-gris-about ">
                <h3>{t("Profesionalismo_Texto")}</h3>
              </div>
            </div>

            <div className="flex flex-col items-center w-full p-6 shadow-md lg:w-11/12 rounded-xl gap-y-10 shadow-gris">
              <div className="h-auto ">
                <img src={Bombilla} alt="skills" />
              </div>
              <div className="h-auto text-lg font-bold uppercase lg:text-xl">
                <h2> {t("Confianza")}</h2>
              </div>
              <div className="h-auto text-sm font-light lg:text-base text-gris-about ">
                <h3>{t("Confianza_Texto")}</h3>
              </div>
            </div>

            <div className="flex flex-col items-center w-full p-6 shadow-md lg:w-11/12 rounded-xl gap-y-10 shadow-gris">
              <div className="h-auto ">
                <img src={Aprobado} alt="skills" />
              </div>
              <div className="h-auto text-lg font-bold uppercase lg:text-xl">
                <h2>{t("Personalizacion")}</h2>
              </div>
              <div className="h-auto text-sm font-light lg:text-base text-gris-about ">
                <h3>{t("Personalizacion_Texto")}</h3>
              </div>
            </div>

            <div className="flex flex-col items-center w-full p-6 shadow-md lg:w-11/12 rounded-xl gap-y-10 shadow-gris">
              <div className="h-auto ">
                <img src={Maleta} alt="skills" />
              </div>
              <div className="h-auto text-lg font-bold uppercase lg:text-xl">
                <h2>{t("Asesoramiento")}</h2>
              </div>
              <div className="h-auto text-sm font-light lg:text-base text-gris-about ">
                <h3>{t("Asesoramiento_Texto")}</h3>
              </div>
            </div>

            <div className="flex flex-col items-center w-full p-6 shadow-md lg:w-11/12 rounded-xl gap-y-10 shadow-gris">
              <div className="h-auto ">
                <img src={Bombilla} alt="skills" />
              </div>
              <div className="h-auto text-lg font-bold uppercase lg:text-xl">
                <h2>{t("Pericia")}</h2>
              </div>
              <div className="h-auto text-sm font-light lg:text-base text-gris-about ">
                <h3>{t("Pericia_Texto")}</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
      <Footer />
    </>
  );
}
