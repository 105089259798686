import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import Home from "./Pages/Home/Home";
import Sell from "./Pages/Sell/Sell";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Rent from "./Pages/Rent/Rent";
import Favourites from "./Pages/Favourites/Favourites";
import LanguageComponent from "./Components/Language";
import House from "./Pages/House/House";
import Tossademar from "./Pages/Locations/Tossademar";
import Santfeliu from "./Pages/Locations/Santfeliu";
import Sagaro from "./Pages/Locations/Sagaro";
import Platja from "./Pages/Locations/Platja";
import Lloretdemar from "./Pages/Locations/Lloretdemar";
import Calonge from "./Pages/Locations/Calonge";
import Begur from "./Pages/Locations/Begur";
import Blanes from "./Pages/Locations/Blanes";
import Santa from "./Pages/Locations/Santa";
// import FavoriteHouses from "./Components/Heart";
import Search from "./Pages/Search/Search";
import AllHouses from "./Pages/Houses/AllHouses";
import Buyers from "./Pages/Buy/Buy";
// import i18n from "i18next";
import Terms from "./Pages/Terms/Terms";
import { useTranslation } from "react-i18next";
import Legal from "./Pages/Terms/Legal";
// import CookieConsent from "react-cookie-consent";
import Blog from "./Pages/Blog/blog";
import Post from "./Pages/Blog/post";
import Login from "./Pages/Login/Login";
import Admin from "./Pages/Admin/Admin";
import CreatePost from "./Pages/Admin/CrearPost";
import AdminPrivateRoute from "./AdminPrivateRoute";
import EditPost from "./Pages/Admin/EditPost";

axios.defaults.baseURL = "https://api.kaylamrealty.com/";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.post["Content-Type"] = "application/json"; // Utiliza application/json para las solicitudes JSON
axios.defaults.headers.post["Accept"] = "application/json";
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  const location = useLocation();
  const { i18n } = useTranslation();
  // const [cityText, setCityText] = useState("");

  React.useEffect(() => {
    const language = location.pathname.split("/")[1];
    i18n.changeLanguage(language);
  }, [location.pathname, i18n]);
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/es/" />
        </Route>
        <Route exact path="/:lang">
          <Home />
        </Route>
        <Route path="/:lang/Buy">
          <Buyers />
        </Route>

        <Route path="/:lang/Sell">
          <Sell />
        </Route>

        <Route path="/:lang/Rent">
          <Rent />
        </Route>

        <Route path="/:lang/About">
          <About />
        </Route>

        <Route path="/:lang/Login">
          <Login />
        </Route>
        <AdminPrivateRoute exact path="/:lang/Admin" component={Admin} />
        <AdminPrivateRoute
          exact
          path="/:lang/Admin/Create/Post"
          component={CreatePost}
        />
        <AdminPrivateRoute
          exact
          path="/:lang/Admin/Edit/Post/:id"
          component={EditPost}
        />

        <Route path="/:lang/Blog/Post/:title">
          <Post />
        </Route>

        <Route path="/:lang/Blog">
          <Blog />
        </Route>

        <Route path="/:lang/Contact">
          <Contact />
        </Route>

        <Route path="/:lang/Favourites">
          <Favourites />
        </Route>

        <Route path="/:lang/Houses/Search">
          <Search />
        </Route>

        <Route path="/:lang/Houses/All">
          <AllHouses />
        </Route>

        <Route path="/:lang/House/:ref">
          <House />
        </Route>

        <Route path="/:lang/Terms">
          <Terms />
        </Route>

        <Route path="/:lang/Legal">
          <Legal />
        </Route>

        <Route path="/:lang/Tossademar">
          <Tossademar cityText="Tossa de Mar" />
        </Route>
        <Route path="/:lang/SantfeliuDeGuixols">
          <Santfeliu cityText="Sant Feliu de Guíxols" />
        </Route>
        <Route path="/:lang/Sagaro">
          <Sagaro cityText="S'agaro" />
        </Route>
        <Route path="/:lang/PlatjaDaro">
          <Platja cityText="Platja d'Aro" />
        </Route>

        <Route path="/:lang/Lloretdemar">
          <Lloretdemar cityText="Lloret de Mar" />
        </Route>

        <Route path="/:lang/Calonge">
          <Calonge cityText="Calonge" />
        </Route>
        <Route path="/:lang/Blanes">
          <Blanes cityText="Blanes" />
        </Route>
        <Route path="/:lang/Begur">
          <Begur cityText="Begur" />
        </Route>

        <Route path="/:lang/SantaCristinaDaro">
          <Santa cityText="Santa Cristina d'Aro" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
