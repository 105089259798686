import CookieConsent from "react-cookie-consent";
import { useEffect, useState } from "react"; // Importa useEffect y useState

export default function Cookie() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Define el estado para determinar si es un dispositivo móvil

  useEffect(() => {
    // Actualiza el estado de isMobile cuando cambia el ancho de la ventana
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cookieConfig = {
    location: "bottom",
    buttonText: "Aceptar",
    cookieName: "miCookieDeCookies",
    style: {
      background: "#BBA388",
      height: isMobile ? "30%" : "80px", // Usa el estado para determinar la altura
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 20px",
      color: "#fff",
      fontSize: "14px",
      lineHeight: "1.4",
    },
    buttonStyle: {
      color: "#fff",
      fontSize: "13px",
      background: "#161C32",
      border: "none",
      borderRadius: "4px",
      padding: "6px 12px",
      cursor: "pointer",
    },
    expires: 150,
  };

  return (
    <>
      <div className="">
        <CookieConsent {...cookieConfig} overlay containerClasses="" >
          <span>
            En <strong>KaylamRealty</strong>, nos preocupamos por brindarte la
            mejor experiencia en nuestro sitio web. Utilizamos cookies para
            optimizar tu visita y ofrecerte un servicio más personalizado. Al
            continuar navegando, estás aceptando nuestra política de cookies.
          </span>
        </CookieConsent>
      </div>
    
    </>
  );
}
