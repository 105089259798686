import { useTranslation } from "react-i18next";
import review from "../assets/icons/stars.png";

export default function ReviewsComponent() {
  const { t } = useTranslation();
  return (
    <>
      <section className="flex flex-col w-full h-auto p-4 lg:p-10 gap-y-4 lg:gap-y-10">
        <div className="w-full h-auto text-lg font-bold text-center font-minion lg:text-3xl">
          <h2>{t("Opinion")}</h2>
        </div>
        <div className="w-full h-auto font-sans">
          <div className="w-full h-6 rounded bg-gris-blanco"></div>
          <div className="flex flex-col w-full h-auto p-8 shadow-lg shadow-gris lg:justify-evenly lg:flex-row gap-y-12">
            <div className="flex flex-col w-full h-auto gap-y-8 lg:gap-y-10 lg:w-3/12">
              <div className="w-full h-32">
                <img alt="review1" className="rounded-full " src={review} />
              </div>
              <div className="w-full h-auto text-base italic font-light text-gris">
                <h2>{t("Review")}</h2>
              </div>
              <div className="flex flex-col w-full h-8 gap-y-2">
                <h3 className="text-sm font-medium">Mardjerie Wijntuin</h3>
              </div>
            </div>

            <div className="flex flex-col w-full h-auto gap-y-8 lg:gap-y-10 lg:w-3/12">
              <div className="w-full h-32">
                <img alt="review1" className="" src={review} />
              </div>
              <div className="w-full h-auto text-base italic font-light text-gris">
                <h2>{t("Review_1")}</h2>
              </div>
              <div className="flex flex-col w-full h-8 gap-y-2">
                <h3 className="text-sm font-medium">CARLOS GUTIERREZ</h3>
              </div>
            </div>

            <div className="flex flex-col w-full h-auto gap-y-8 lg:gap-y-10 lg:w-3/12">
              <div className="w-full h-32">
                <img alt="review1" className="rounded-full " src={review} />
              </div>
              <div className="w-full h-auto text-base italic font-light text-gris">
                <h2>{t("Review_2")}</h2>
              </div>
              <div className="flex flex-col w-full h-8 gap-y-2">
                <h3 className="text-sm font-medium">Elena Cioroiu</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
