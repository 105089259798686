import Alicante from "../assets/images/localidades/begur.png";
import Barcelona from "../assets/images/localidades/blanes.png";
import Costabrava from "../assets/images/localidades/Calonge.jpeg";
import Madrid from "../assets/images/localidades/Lloret.png";
import Menorca from "../assets/images/localidades/Platja.png";
import Tarragona from "../assets/images/localidades/Sagaro.png";
import Pedro from "../assets/images/localidades/Sant.png";
import Andrea from "../assets/images/localidades/Tossa.png";
import Miami from "../assets/images/localidades/cristina.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Locations() {
    const { t } = useTranslation();

  return (
    <>
      <div className="w-full min-h-screen">
        <section className="flex flex-col items-center w-full h-full pt-14">
          <div className="flex flex-col w-full h-40 mb-8 justify-evenly">
            <div className="w-full">
              <h2 className="text-2xl font-bold text-center font-minion lg:text-4xl ">
                {t("Our_Top_Locations")}
              </h2>
            </div>
            <div className="w-full font-sans">
              <h3 className="hidden text-center lg:block text-gris">
                {t("Debajo_Locations_1")} <br />
                {t("Debajo_Locations_2")}
              </h3>

              <h3 className="p-4 text-base text-center lg:hidden text-gris">
                {t("Debajo_Locations")}
              </h3>
            </div>
          </div>
          <div className="flex flex-col w-11/12 font-sans lg:grid gap-y-6 lg:grid-cols-6 lg:grid-rows-3 lg:gap-8 lg:justify-items-center">
          <Link to="/:lang/Blanes" className="flex items-center justify-center w-full col-span-2 row-span-1 transition-all hover:scale-105 h-09/12">
              <img
                alt="Ciudad"
                className="w-full h-full brightness-75"
                src={Barcelona}
              />
              <h2 className="absolute text-3xl font-light text-blanco ">
                Blanes
              </h2>
            </Link>
            <Link to="/:lang/Lloretdemar" className="flex items-center justify-center w-full col-span-2 row-span-1 transition-all hover:scale-105 h-09/12">
              <img
                alt="Ciudad"
                className="w-full h-full brightness-75"
                src={Madrid}
              />
              <h2 className="absolute text-3xl font-light text-blanco ">
                Lloret De Mar
              </h2>
            </Link>
            <Link to="/:lang/Tossademar" className="flex items-center justify-center w-full col-span-2 row-span-1 transition-all hover:scale-105 h-09/12">
              <img
                alt="Ciudad"
                className="w-full h-full brightness-75"
                src={Andrea}
              />
              <h2 className="absolute text-3xl font-light text-blanco ">
                Tossa De Mar
              </h2>
            </Link>
             <Link to="/:lang/SantaCristinaDaro" className="flex items-center justify-center w-full col-span-2 row-span-1 transition-all hover:scale-105 h-09/12">
              <img
                alt="Ciudad"
                className="w-full h-full brightness-75"
                src={Miami}
              />
              <h2 className="absolute text-3xl font-light text-blanco ">
              Santa Cristina d’Aro
              </h2>
            </Link>
            <Link to="/:lang/SantfeliuDeGuixols" className="flex items-center justify-center w-full col-span-2 row-span-1 transition-all hover:scale-105 h-09/12">
              <img
                alt="Ciudad"
                className="w-full h-full brightness-75"
                src={Pedro}
              />
              <h2 className="absolute text-3xl font-light text-blanco ">
                Sant Feliu De Guixols
              </h2>
            </Link>
            <Link to="/:lang/Sagaro" className="flex items-center justify-center w-full col-span-2 row-span-1 transition-all hover:scale-105 h-09/12">
              <img
                alt="Ciudad"
                className="w-full h-full brightness-75"
                src={Tarragona}
              />
              <h2 className="absolute text-3xl font-light text-blanco ">
                S'Agaro
              </h2>
            </Link>
            <Link to="/:lang/PlatjaDaro" className="items-center justify-center hidden w-full col-span-2 row-span-1 transition-all lg:flex hover:scale-105 h-09/12">
              <img
                alt="Ciudad"
                className="w-full h-full brightness-75"
                src={Menorca}
              />
              <h2 className="absolute text-3xl font-light text-blanco ">
                Platja D'Aro
              </h2>
            </Link>
           
           
            <Link to="/:lang/Calonge" className="items-center justify-center hidden w-full col-span-2 row-span-1 transition-all lg:flex hover:scale-105 h-09/12">
              <img
                alt="Ciudad"
                className="w-full h-full brightness-75"
                src={Costabrava}
              />
              <h2 className="absolute text-3xl font-light text-blanco ">
                Calonge
              </h2>
            </Link>
            <Link to="/:lang/Begur" className="relative items-center justify-center hidden w-full col-span-2 row-span-1 transition-all lg:flex hover:scale-105 h-09/12">
              <img
                alt="Ciudad"
                className="w-full h-full brightness-75"
                src={Alicante}
              />
              <h2 className="absolute text-3xl font-light text-blanco ">
                Begur
              </h2>
            </Link>
           
       
        
          
           
          </div>
          <div className="flex items-center w-full h-40 mt-4 font-sans justify-evenly">
            <div className="lg:w-3/12 w-2/12 border-2 h-0.5 border-gris-claro"></div>
            <div className="flex items-center justify-center w-6/12 h-16 rounded-sm lg:w-3/12 bg-gris-blanco">
              <Link to="/:lang/Houses/All" className="text-xs font-medium lg:font-light lg:text-base text-blanco">
              {t("Ver_Inmuebles")}

              </Link>
            </div>
            <div className="lg:w-3/12 w-2/12 h-0.5 border-2 border-gris-claro"></div>
          </div>
        </section>
      </div>
    </>
  );
}
