import Phone from "../assets/icons/Group 34845.svg";
import Email from "../assets/icons/Group 34846.svg";
import Location from "../assets/icons/Group 34847.svg";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import swal from "sweetalert";

export default function ContactForm({ Ref }) {
  const [registerInput, setRegister] = useState({
    Nombre: "",
    Telefono: "",
    Correo: "",
    Texto: "",
    Ref: "",
    error_list: [],
  });

  const handleInput = (e) => {
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    data.append("Nombre", registerInput.Nombre);
    data.append("Correo", registerInput.Correo);
    data.append("Telefono", registerInput.Telefono);
    data.append("Texto", registerInput.Texto);
    data.append("Ref", Ref); // Add the Ref prop to the data

    await axios.post("api/createContact", data).then((res) => {
      if (res.data.status === 200) {
        swal({
          title: "Mensaje enviado",
          text: "Le contactaremos lo antes posible",
          icon: "success",
          button: "Continuar",
        });
        setRegister({
          Nombre: "",
          Correo: "",
          Telefono: "",
          Texto: "",
          Ref: "",
        });
      } else {
        setRegister({
          ...registerInput,
          error_list: res.data.validation_errors,
        });
      }
    });
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="z-0 flex items-center w-full min-h-screen">
        <section className="flex flex-col items-center w-11/12 mx-auto my-0 lg:flex-row h-5/6 lg:h-screen ">
          <div className="flex items-center justify-center w-full mb-8 lg:w-2/6 h-5/6 lg:h-4/6 bg-gris-gris rounded-xl">
            <div className="w-5/6 h-full mx-auto my-0 lg:flex lg:flex-col lg:gap-y-6 ">
              <div className="flex flex-col w-full pt-12 pb-2 justify-evenly h-2/6">
                <h2 className="pb-2 text-lg font-bold text-black font-minion ">
                  {t("Contactenos")}
                </h2>
                <h3 className="font-sans text-sm font-medium text-black lg:text-base ">
                  {t("Contacto_Form")}
                  <br />
                  {t("Contacto_Form_1")}
                </h3>
              </div>
              <div className="flex flex-col w-full pt-8 pb-10 font-sans gap-y-6 justify-evenly h-3/6 lg:pt-0 lg:pb-8">
                <div className="flex items-center justify-between w-full h-1/6">
                  <div className="flex items-center w-1/6 h-full">
                    <img alt="icono" className="w-6 lg:w-8" src={Phone} />
                  </div>
                  <div className="flex flex-col justify-center w-5/6 h-full">
                    <div>
                      <h3 className="text-xs text-gris">{t("Llamenos")}</h3>
                    </div>
                    <div>
                      <a
                        href="tel:+34972608553"
                        className="text-sm text-black lg:text-lg"
                      >
                        +34 687 166 880{" "}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full h-1/6">
                  <div className="flex items-center w-1/6 h-full ">
                    <img alt="icono" className="w-6 lg:w-8" src={Email} />
                  </div>
                  <div className="flex flex-col justify-center w-5/6 h-full">
                    <div>
                      <h3 className="text-xs text-gris">Email</h3>
                    </div>
                    <div>
                      <a
                        href="mailto:info@kaylamrealty.com"
                        className="text-sm text-black lg:text-lg"
                      >
                        info@kaylamrealty.com
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full h-1/4">
                  <div className="flex items-center w-1/6 h-full ">
                    <img alt="icono" className="w-6 lg:w-8" src={Location} />
                  </div>
                  <div className="flex flex-col justify-center w-5/6 h-full">
                    <div>
                      <h3 className="text-xs text-gris">Location</h3>
                    </div>
                    <div>
                      <a
                        href="https://goo.gl/maps/tqVVNujNgqz9SMJQA"
                        className="text-sm text-black lg:text-lg"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Galerías Albatros 118, 17250 Platja d'Aro, Girona.
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-full lg:w-4/6 h-4/6">
            <form
              onSubmit={registerSubmit}
              className="flex flex-col w-full h-full gap-y-4 lg:w-11/12 bg-blanco justify-evenly"
            >
              <input
                type="text"
                placeholder={t("Nombre")}
                className="w-full pl-8 font-light text-black rounded-lg h-14 bg-gris-gris"
                onChange={handleInput}
                value={registerInput.Nombre}
                name="Nombre"
              />
              <input
                type="email"
                placeholder={t("Correo")}
                className="w-full pl-8 font-light text-black rounded-lg h-14 bg-gris-gris "
                onChange={handleInput}
                value={registerInput.Correo}
                name="Correo"
              />

              <div className="flex items-center w-full pl-8 font-light text-black border border-black rounded-lg h-14 bg-gris-gris">
                <PhoneInput
                  placeholder={t("Telefono")}
                  defaultCountry="ES"
                  international
                  value={registerInput.Telefono}
                  onChange={(value) =>
                    handleInput({ target: { name: "Telefono", value } })
                  }
                  className="text-black"
                  countryCallingCodeEditable={false}
                />
              </div>

              <input
                type="text"
                placeholder={t("Mensaje")}
                onChange={handleInput}
                value={registerInput.Texto}
                name="Texto"
                className="w-full h-40 pl-8 font-light text-black rounded-lg bg-gris-gris "
              />
              <input
                type="submit"
                value={t("Enviar")}
                className="w-full rounded-md h-14 text-blanco bg-gris-blanco "
              />
            </form>
          </div>
        </section>
      </div>
    </>
  );
}
