import lupa from "../assets/icons/Path 8.svg";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useHistory, useParams } from "react-router-dom";
import Advanced from "../assets/icons/advanced.png";
import { useTranslation } from "react-i18next";
import { useApi } from "../Context/ApiContext";

export default function Filter() {
  const { t } = useTranslation();
  const { lang } = useParams();
  const apiData = useApi();

  const history = useHistory();

  // const [types, setTypes] = useState([]);
  // const [locations, setLocations] = useState([]);

  const [cities, setCities] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);

  const [isFilterVisible, setFilterVisible] = useState(true);

  const handleToggleFilter = () => {
    setFilterVisible(!isFilterVisible);
  };

  useEffect(() => {
    if (apiData) {
      // Extract cities
      const cities = apiData
        .map((house) => house.ciudad)
        .filter((value, index, self) => self.indexOf(value) === index);
      const uniqueCities = cities.reduce((acc, curr) => {
        if (!acc.includes(curr)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      setCities(uniqueCities);
      console.log(uniqueCities);

      // Extract property types
      const propertyTypes = apiData
        .map((house) => house.tipo_ofer)
        .filter((value, index, self) => self.indexOf(value) === index);
      const uniqueTypes = propertyTypes.reduce((acc, curr) => {
        if (!acc.includes(curr)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      setPropertyTypes(uniqueTypes);
    }

    Cookies.set("i18next", lang);
  }, [apiData, lang]);
  const [registerInput, setRegisterInput] = useState({
    tipo: "",
    city: "",
    error_list: [],
  });

  const handleInput = (e) => {
    setRegisterInput({ ...registerInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (apiData) {
        const filteredData = apiData.filter((propiedad) => {
          return (
            (!registerInput.tipo ||
              (propiedad.tipo_ofer &&
                propiedad.tipo_ofer === registerInput.tipo)) &&
            (!registerInput.city ||
              (propiedad.ciudad && propiedad.ciudad === registerInput.city)) &&
            (!registerInput.precio ||
              (propiedad.precioinmo &&
                parseInt(propiedad.precioinmo) <=
                  parseInt(registerInput.precio))) &&
            (!registerInput.Habitaciones ||
              (propiedad.habdobles &&
                checkHabitaciones(
                  propiedad.habdobles,
                  registerInput.Habitaciones
                ))) &&
            (!registerInput.ref ||
              (propiedad.ref &&
                propiedad.ref.toLowerCase() ===
                  registerInput.ref.toLowerCase())) &&
            (!registerInput.vistas_al_mar ||
              (propiedad.vistasalmar &&
                convertToBoolean(propiedad.vistasalmar) ===
                  convertToBoolean(registerInput.vistas_al_mar))) &&
            (!registerInput.Licencia ||
              (propiedad.licencia_turistica &&
                convertToBoolean(propiedad.licencia_turistica) ===
                  convertToBoolean(registerInput.Licencia))) &&
            (!registerInput.Piscina ||
              (propiedad.piscina_prop &&
                convertToBoolean(propiedad.piscina_prop) ===
                  convertToBoolean(registerInput.Piscina)))
          );
        });

        history.push({
          pathname: `/${lang}/Houses/Search`,
          state: {
            houses: filteredData,
          },
        });

        // Haz algo con los datos filtrados
      }
    } catch (error) {
      console.error("Error al manejar el submit:", error);
    }
  };

  // Función auxiliar para obtener la traducción de una palabra
  const getTranslation = (word) => {
    if (word === "Casa") return t("Tcasa");
    if (word === "Parcela") return t("Parcelas");
    if (word === "Macia") return t("Tmacia");
    if (word === "Dúplex") return t("Tduplex");
    if (word === "Apartamento") return t("Tapartamento");
    if (word === "Terreno") return t("Terreno");
    if (word === "Casa Adosada") return t("Tcasa_adosada");
    // Agrega más condiciones según sea necesario

    return word; // Si la palabra no tiene traducción, se muestra tal como está
  };

  const checkHabitaciones = (propiedadHabitaciones, selectedHabitaciones) => {
    if (selectedHabitaciones === "1-2") {
      return propiedadHabitaciones >= 1 && propiedadHabitaciones <= 2;
    } else if (selectedHabitaciones === "2-3") {
      return propiedadHabitaciones >= 2 && propiedadHabitaciones <= 3;
    } else if (selectedHabitaciones === "4-5") {
      return propiedadHabitaciones >= 4 && propiedadHabitaciones <= 5;
    } else if (selectedHabitaciones === "+5") {
      return propiedadHabitaciones >= 5;
    } else {
      return true; // Si no se selecciona ningún valor, no se aplica filtro por habitaciones
    }
  };

  const convertToBoolean = (value) => {
    return value === "1" ? true : false;
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center justify-center w-full font-sans sm:w-11/12 h-3/6"
      >
        <div className="flex justify-between w-11/12 p-2 rounded-md sm:p-3 h-2/6 lg:h-3/6 lg:w-3/5 bg-blanco">
          <div className="flex flex-col justify-around w-5/12 h-full">
            <div className="flex items-center h-2/6">
              <h2 className="uppercase sm:text-xs text-small1 md:text-sm text-gris">
                {t("Propiedad")}
              </h2>
            </div>
            <div className="flex items-center justify-between h-4/6 lg:w-full">
              <select
                className="w-full text-black border-none focus:border-transparent sm:text-sm lg:text-sm text-small"
                id=""
                name="tipo"
                onChange={handleInput}
                value={registerInput.tipo}
              >
                <option value={""} label={t("Tipos")}>
                  {t("Tipos")}
                </option>

                {propertyTypes.map((item) => {
                  return (
                    <option
                      key={item}
                      value={item}
                      label={getTranslation(item)}
                    >
                      {getTranslation(item)}
                    </option>
                  );
                })}
              </select>

              {/* <h3 className="text-black sm:text-sm md:text-base text-small">{t("Casa")}</h3> */}
              {/* <div className="flex items-center w-1/4 h-full">
                <img src={drop} className="w-2" alt="" />
              </div> */}
            </div>
          </div>
          <div className="flex flex-col justify-around w-3/12 h-full">
            <div className="flex items-center h-2/6">
              <h2 className="uppercase sm:text-xs md:text-sm text-small1 text-gris">
                {t("Referencia")}
              </h2>
            </div>
            <div className="flex items-center justify-between h-4/6 w-4/4">
              <input
                type="text"
                className="w-16 text-black border-none lg:w-24 placeholder:text-black text-small md:text-sm"
                name="ref"
                placeholder={t("Introduce_Referencia")}
                id=""
                onChange={handleInput}
                value={registerInput.ref}
              />
            </div>
          </div>
          <div className="flex flex-col justify-around w-5/12 h-full">
            <div className="flex items-center h-2/6">
              <h2 className="uppercase sm:text-xs md:text-sm text-small1 text-gris">
                {t("Localidad")}
              </h2>
            </div>
            <div className="flex items-center justify-between h-4/6 w-4/4">
              <select
                name="city"
                onChange={handleInput}
                value={registerInput.city}
                className="w-full text-black border-none focus:border-transparent lg:text-sm text-small"
                id=""
              >
                <option value={""} label={t("Ubicacion")}>
                  {t("Ubicacion")}
                </option>

                {cities.map((item) => {
                  return (
                    <option key={item} value={item} label={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
              {/* <div className="flex items-center w-1/4 h-full">
                <img src={drop} className="w-2" alt="" />

              </div> */}
            </div>
          </div>

          <button className="flex flex-row items-center justify-around w-2/12 h-full pl-1 rounded md:p-2 sm:p-1 bg-gris-blanco">
            <div className="flex items-center h-4/6">
              <h3 className="font-thin text-small sm:text-sm md:text-base text-blanco">
                {t("Buscar")}
              </h3>
            </div>
            <div className="flex items-center h-2/6">
              <img src={lupa} className="w-2 sm:w-4" alt="lupa" />
            </div>
          </button>
        </div>

        <div
          id="filter"
          className={`justify-between ${
            isFilterVisible ? "hidden" : "flex"
          } w-11/12 h-16 p-2 mt-6 rounded-md sm:p-3 lg:h-32 lg:w-4/5 bg-blanco`}
        >
          <div className="flex flex-col justify-around w-3/12 h-full">
            <div className="flex items-center h-2/6">
              <h2 className="uppercase sm:text-xs text-small1 md:text-sm text-gris">
                {t("Precio")}
              </h2>
            </div>
            <div className="flex items-center justify-between w-4/4 lg:w-3/4 h-4/6 ">
              <select
                className="w-full p-0 pr-0 text-black border-none focus:border-transparent sm:text-sm lg:text-sm text-small"
                id=""
                name="precio"
                onChange={handleInput}
                value={registerInput.precio}
              >
                <option value={""} label={t("Precio")}>
                  {t("Precio")}
                </option>

                <option value="200000">200.000 €</option>
                <option value="300000">300.000 €</option>
                <option value="400000">400.000 €</option>
                <option value="500000">500.000 €</option>
                <option value="750000">750.000 €</option>
                <option value="900000">900.000 €</option>
                <option value="1000000">1.000.000 €</option>
                <option value="1250000">1.250.000 €</option>
                <option value="1500000">1.500.000 €</option>
                <option value="2000000">2.000.000 €</option>
                <option value="5000000">5.000.000 €</option>
                <option value="10000000">10.000.000 €</option>
                <option value="20000000">20.000.000 €</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col justify-around w-3/12 h-full">
            <div className="flex items-center h-2/6">
              <h2 className="uppercase sm:text-xs md:text-sm text-small1 text-gris">
                {t("Habitaciones")}
              </h2>
            </div>
            <div className="flex items-center justify-between w-4/4 lg:w-3/4 h-4/6">
              <select
                name="Habitaciones"
                onChange={handleInput}
                value={registerInput.Habitaciones}
                className="w-full p-0 text-black border-none sm:text-sm focus:border-transparent text-small lg:text-sm"
                id=""
              >
                <option value={""} label={t("Habitaciones")}>
                  {t("Habitaciones")}
                </option>

                <option value="1-2">1-2</option>
                <option value="2-3">2-3</option>
                <option value="3-4">3-4</option>
                <option value="4-5">4-5</option>
                <option value="5+">5+</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col justify-around w-3/12 h-full">
            <div className="flex items-center h-2/6">
              <h2 className="uppercase sm:text-xs md:text-sm text-small1 text-gris">
                {t("Turistas")}
              </h2>
            </div>
            <div className="flex items-center justify-between h-4/6 w-4/4">
              <select
                name="Licencia"
                className="w-full p-0 text-black border-none focus:border-transparent lg:text-sm text-small"
                id=""
                onChange={handleInput}
                value={registerInput.Licencia}
              >
                <option value={""} label={t("Turistas")}>
                  {t("Turistas")}
                </option>
                <option value="1">{t("Si")}</option>
                <option value="0">{t("No")}</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col justify-around w-3/12 h-full">
            <div className="flex items-center h-2/6">
              <h2 className="uppercase sm:text-xs md:text-sm text-small1 text-gris">
                {t("Vistas_al_mar")}
              </h2>
            </div>
            <div className="flex items-center justify-between w-3/4 h-4/6">
              <select
                name="vistas_al_mar"
                className="w-full p-0 text-black border-none focus:border-transparent lg:text-sm text-small"
                id=""
                onChange={handleInput}
                value={registerInput.vistas_al_mar}
              >
                <option value={""} label={t("Vistas_al_mar")}>
                  {t("Vistas_al_mar")}
                </option>
                <option value="1">{t("Si")}</option>
                <option value="0">{t("No")}</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col justify-around w-3/12 h-full">
            <div className="flex items-center h-2/6">
              <h2 className="uppercase sm:text-xs md:text-sm text-small1 text-gris">
                {t("Piscina_Propia")}
              </h2>
            </div>
            <div className="flex items-center justify-between w-4/4 h-4/6">
              <select
                name="Piscina"
                className="w-full p-0 text-black border-none focus:border-transparent lg:text-sm text-small"
                id=""
                onChange={handleInput}
                value={registerInput.Piscina}
              >
                <option value={""} label={t("Piscina_Propia")}>
                  {t("Piscina_Propia")}
                </option>
                <option value="1">{t("Si")}</option>
                <option value="0">{t("No")}</option>
              </select>
            </div>
          </div>

          {/* <div   onClick={handleToggleFilter} className="flex flex-row items-center justify-around w-2/12 h-full pl-1 rounded md:p-2 sm:p-1 bg-gris-blanco">
            <div className="flex items-center h-4/6">
              <h3 className="font-thin text-small sm:text-sm md:text-base text-blanco">
                Apply Filter
              </h3>
            </div>
          </div> */}
        </div>
      </form>
      <div
        onClick={handleToggleFilter}
        className="absolute flex items-center justify-center w-16 h-16 rounded-full lg:mt-4 bg-gris-blanco lg:static"
      >
        <img src={Advanced} className="w-8 lg:w-8 " alt="" />
      </div>
    </>
  );
}
