import i18next from "i18next";
import { useEffect } from "react";
import Cookies from "js-cookie";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
export default function LanguageComponent() {
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const currentLanguage = i18n.language;
    const pathname = location.pathname;
    const languageParam = pathname.split("/")[1]; // Obtiene el primer segmento de la URL

    // Comprueba si el idioma seleccionado y el idioma en la URL son diferentes
    if (currentLanguage !== languageParam) {
      const newPathname = `/${currentLanguage}${pathname.substring(
        languageParam.length + 1
      )}`;
      // Actualiza la URL con el idioma seleccionado
      history.push(newPathname);
    }
  }, [i18n.language, location.pathname, history]);

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "es",
      name: "Español",
      country_code: "es",
    },
    {
      code: "fr",
      name: "Français",
      country_code: "fr",
    },
    {
      code: "de",
      name: "Deutsch",
      country_code: "de",
    },
    {
      code: "nl",
      name: "Nederlands",
      country_code: "nl",
    },
    {
      code: "ru",
      name: "Pусский",
      country_code: "ru",
    },
  ];

  function showDropdownOptions() {
    document.getElementById("options").classList.toggle("hidden");
    // document.getElementById("arrow-up").classList.toggle("hidden");
    // document.getElementById("arrow-down").classList.toggle("hidden");
  }

  function Close() {
    document.getElementById("options").classList.toggle("hidden");
  }

  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    if (currentLanguage) {
      document.body.dir = currentLanguage.dir || "ltr";
    } else {
      console.error(
        "currentLanguage is undefined. Check why this is happening."
      );
    }
  }, [currentLanguage]);

  const GlobeIcon = ({ width = 24, height = 24 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="white"
      className="w-6 h-6"
      width={width}
      height={height}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525"
      />
    </svg>
  );
  return (
    <>
      <div className="z-40 flex flex-col justify-center w-3/12 h-full lg:items-end lg:w-20">
        <button
          onClick={showDropdownOptions}
          className="flex flex-row items-center justify-between w-20 px-2 py-2 bg-transparent border-2 rounded-md outline-none border-marino text-gris focus:outline-none focus:border-gris"
        >
          <GlobeIcon />
          <svg
            className="w-4 h-4 ml-2"
            aria-hidden="true"
            fill="none"
            stroke="white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokelinecap="round"
              strokelinejoin="round"
              strokewidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </button>
        <div
          id="options"
          className="absolute z-20 hidden w-20 bg-white rounded-lg shadow-xl lg:w-24 mt-mt-u "
        >
          <ul className="z-40 text-sm text-white dark:text-gray-200">
            {languages.map(({ code, country_code, name }) => (
              <li
                onClick={Close}
                id="ule"
                key={country_code}
                className="z-30 block py-2 lg:px-2 bg-marino hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <button
                  onClick={() => i18next.changeLanguage(code)}
                  disabled={code === currentLanguageCode}
                  className="z-30 block py-2 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <span
                    className={`flag-icon mx-2 z-30 flag-icon-${country_code} `}
                    style={{ opacity: code === currentLanguageCode ? 0.5 : 1 }}
                  ></span>
                  {name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
