import Footer from "../../Components/Footer";
// import LocationComponent from "../../Components/Location";
import Navbar from "../../Components/Navbar";
import Whatsapp from "../../Components/Whatsapp";
import ContactForm from "./ContactComponent";
// import Sidebar from "../../Components/Sidebar";
// import LanguageComponent from "../../Components/Language";
import Mapa from "../../assets/images/maps.png";

import about from "../../assets/icons/about-pic.png";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function Contact() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <div className="absolute z-30 flex items-center justify-end w-11/12 h-24 ml-6 bg-marino lg:ml-24 lg:h-1/5 ">
        <LanguageComponent />
      </div> */}
      <section className="w-full min-h-full mb-12 bg-marino lg:h-screen lg:max-h-screen">
        {/* <div className="w-full h-24 lg:hidden">
          <Sidebar />
        </div> */}

        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>
        <main className="relative flex flex-col w-full h-full">
          <Navbar />
          <section className="relative flex items-center w-full h-full">
            <div className="absolute z-0 w-full h-full bg-center bg-no-repeat bg-cover brightness-50 bg-Contact"></div>
            {/* <div className="z-10 w-full h-1/6">
                        <h1 className="text-xl text-center text-blanco">Contact Us</h1>
                    </div> */}
            <ContactForm />
          </section>
        </main>
      </section>

      <div className="flex items-center w-full min-h-screen mx-auto my-0 lg:w-10/12">
        <div className="flex flex-col-reverse items-center w-full h-auto p-8 lg:flex-row gap-y-6">
          <div className="flex flex-col w-full h-auto lg:mr-8 gap-y-6">
            <div className="w-full h-auto text-3xl font-bold text-center font-minion lg:text-5xl lg:text-left">
              <h2>Let's Connect!</h2>
            </div>
            <div className="w-full h-auto font-sans text-base font-normal text-justify lg:font-light lg:text-lg ">
              <h3>
                {t("Titulo_Contacto")}
                <br />
                {t("Titulo_Contacto_1")}
                <br />
                {t("Titulo_Contacto_2")}
              </h3>
            </div>
          </div>
          <div className="w-full h-auto p-2 lg:w-3/5 lg:mr-10">
            <img alt="Ciudad" className="w-full" src={about} />
          </div>
        </div>
      </div>

      <div className="flex justify-center w-full mt-12 min-h-40">
        <section className="flex flex-col items-center w-full h-5/6">
          <div className="flex flex-col w-full h-full mb-8 gap-y-10 justify-evenly">
            <div className="w-full">
              <h2 className="text-2xl font-bold text-center font-minion lg:text-4xl">
                {" "}
                {t("Zona")}
              </h2>
            </div>
            <div className="flex justify-center w-full">
              <img alt="map" src={Mapa} />
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
}
