import Phone from "../../assets/icons/Group 34845.svg";
import Email from "../../assets/icons/Group 34846.svg";
import Location from "../../assets/icons/Group 34847.svg";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";
import axios from "axios";
import swal from "sweetalert";

export default function ContactForm() {
  const [registerInput, setRegister] = useState({
    Nombre: "",
    Telefono: "",
    Correo: "",
    Texto: "",
    error_list: [],
  });

  const handleInput = (e) => {
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    data.append("Nombre", registerInput.Nombre);
    data.append("Correo", registerInput.Correo);
    data.append("Telefono", registerInput.Telefono);
    data.append("Texto", registerInput.Texto);

    await axios
      .post("https://back.kaylamrealty.com/api/createContact", data)
      .then((res) => {
        if (res.data.status === 200) {
          swal({
            title: "Mensaje enviado",
            text: "Le contactaremos lo antes posible",
            icon: "success",
            button: "Continuar",
          });
          setRegister({
            Nombre: "",
            Correo: "",
            Telefono: "",
            Texto: "",
          });
        } else {
          setRegister({
            ...registerInput,
            error_list: res.data.validation_errors,
          });
        }
      });
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="relative flex flex-col items-center w-full pt-10 pb-10 h-5/6 ">
        <section className="z-10 flex flex-col w-11/12 h-full mx-auto my-0 font-sans gap-y-6 lg:flex-row ">
          <div className="w-full h-full lg:w-2/6 bg-blanco rounded-xl">
            <div className="flex flex-col w-5/6 pt-4 mx-auto my-0 lg:h-full ">
              <div className="flex flex-col w-full mb-5 justify-evenly h-2/6">
                <h2 className="mb-4 text-2xl font-bold text-black ">
                  {t("Contactenos")}
                </h2>
                <h3 className="text-sm font-medium text-black lg:text-base ">
                  {t("Contacto_Form")} <br />
                  {t("Contacto_Form_1")}
                </h3>
              </div>
              <div className="flex flex-col w-full h-4/6 mb-7 gap-y-4 ">
                <div className="flex items-center justify-between w-full h-1/4">
                  <div className="flex items-center w-1/6 h-full">
                    <img alt="icono" className="w-6 lg:w-8" src={Phone} />
                  </div>
                  <div className="flex flex-col justify-center w-5/6 h-full">
                    <div>
                      <h3 className="text-xs text-gris"> {t("Llamenos")}</h3>
                    </div>
                    <div>
                      <a href="tel:+34972608553" className="text-black text-md">
                        {" "}
                        +34 687 166 880{" "}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full h-1/4">
                  <div className="flex items-center w-1/6 h-full ">
                    <img alt="icono" className="w-6 lg:w-8" src={Email} />
                  </div>
                  <div className="flex flex-col justify-center w-5/6 h-full">
                    <div>
                      <h3 className="text-xs text-gris">Email</h3>
                    </div>
                    <div>
                      <a
                        href="mailto:info@kaylamrealty.com"
                        className="text-black text-md"
                      >
                        info@kaylamrealty.com
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full h-1/4">
                  <div className="flex items-center w-1/6 h-full ">
                    <img alt="icono" className="w-6 lg:w-8" src={Location} />
                  </div>
                  <div className="flex flex-col justify-center w-5/6 h-full">
                    <div>
                      <h3 className="text-xs text-gris">Location</h3>
                    </div>
                    <div>
                      <a
                        href="https://goo.gl/maps/tqVVNujNgqz9SMJQA"
                        className="text-black text-md"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Galerías Albatros 118, 17250 Platja d'Aro, Girona.
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-full h-full lg:w-4/6">
            <form
              onSubmit={registerSubmit}
              className="flex flex-col w-full h-full gap-y-4 lg:w-11/12 justify-evenly"
            >
              <input
                type="text"
                name="Nombre"
                onChange={handleInput}
                value={registerInput.Nombre}
                placeholder={t("Nombre")}
                className="w-full pl-8 font-light text-black rounded-lg outline-none h-14 bg-blanco"
              />
              <input
                type="email"
                name="Correo"
                onChange={handleInput}
                value={registerInput.Correo}
                placeholder={t("Correo")}
                className="w-full pl-8 font-light text-black rounded-lg outline-none h-14 bg-blanco "
              />
              <div className="flex items-center w-full pl-8 font-light text-black border border-black rounded-lg h-14 bg-gris-gris">
                <PhoneInput
                  placeholder={t("Telefono")}
                  defaultCountry="ES"
                  international
                  className="text-black"
                  value={registerInput.Telefono}
                  onChange={(value) =>
                    handleInput({ target: { name: "Telefono", value } })
                  }
                  countryCallingCodeEditable={false}
                />
              </div>
              <input
                type="text"
                placeholder={t("Mensaje")}
                onChange={handleInput}
                value={registerInput.Texto}
                name="Texto"
                className="w-full h-40 pl-8 font-light text-black rounded-lg outline-none bg-blanco "
              />
              <input
                type="submit"
                value={t("Enviar")}
                className="w-full rounded-md outline-none h-14 text-blanco bg-gris-blanco "
              />
            </form>
          </div>
        </section>
      </div>
    </>
  );
}
