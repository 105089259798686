import { useTranslation } from "react-i18next";
import ContactForm from "../../Components/Contact";
import Footer from "../../Components/Footer";
import HousesComponent from "../../Components/Houses";
import LocationComponent from "../../Components/Location";
import Locations from "../../Components/Locations";
import Navbar from "../../Components/Navbar";
import ReviewsComponent from "../../Components/Reviews";
import Whatsapp from "../../Components/Whatsapp";
import Filter from "../../Components/Filter";

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      {/* <div className="absolute z-30 flex items-center justify-end w-11/12 h-24 ml-6 bg-marino lg:ml-24 lg:h-1/5 ">
        <LanguageComponent />
      </div> */}

      <div className="relative w-screen h-screen ">
        <div className="absolute flex items-end justify-end w-full h-full">
          <Whatsapp />
        </div>
        {/* <div className="w-full h-24 lg:hidden">
          <Sidebar />
        </div>{" "} */}
        <main className="relative flex flex-col w-full h-full lg:bg-marino">
          <Navbar />

          <section className="relative flex w-full h-full ">
            <div className="absolute z-0 w-full h-full bg-center bg-no-repeat bg-cover brightness-50 bg-Homepage"></div>
            <div className="z-10 flex flex-col justify-center w-full">
              <header className="flex flex-col items-center justify-evenly h-3/4 ">
                <div className="flex items-center justify-center w-full sm:w-1/2 lg:w-5/6 h-2/4">
                  <h1 className="text-3xl text-center font-minion sm:hidden text-blanco">
                    {t("Titulo_Home")}
                  </h1>
                  <h1 className="hidden text-4xl text-center font-minion md:text-5xl lg:text-6xl sm:block text-blanco">
                    {t("Titulo_Home_Responsive")}

                    {t("Titulo_Home_Responsive_1")}
                  </h1>
                </div>
                <Filter />
              </header>
            </div>
          </section>
        </main>
      </div>

      <Locations />

      <HousesComponent />

      <ReviewsComponent />

      <ContactForm />

      <LocationComponent />

      <Footer />
    </>
  );
}
